import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Toolbar extends Component {
    render() {
        return (
            <div className="pdf-toolbar">
                <div className="container flex-row flex-h-between flex-v-center">
                    <div className='flex-row flex-v-center'>
                        <button className="ZoomIn" onClick={() => this.props.onZoomOut()}>-</button>
                        <button className="ZoomOut" onClick={() => this.props.onZoomIn()}>+</button>
                        <div className="ZoomPercent">{(this.props.scale * 100).toFixed(1)}%</div>
                    </div>
                    <button className="download" onClick={() => this.props.onDownload()}>
                        <i className="fas fa-download fa-2x"/>
                    </button>
                </div>
            </div>
        );
    }
}

Toolbar.propTypes = {
    scale: PropTypes.number.isRequired,
    onZoomIn: PropTypes.func.isRequired,
    onZoomOut: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired
}

export default Toolbar;
