import React, {Component} from 'react'
import PropTypes from 'prop-types'
import MindsetsJsSDK, {Toggle} from "mindsets-js-sdk";

import analytic from 'utils/Analytic'

import './toggle_answer_keys_action.scss';

class ToggleAnswerKeysAction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            is_on: props.lesson.is_answer_keys_shown
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lesson.is_answer_keys_shown != this.props.lesson.is_answer_keys_shown) {
            this.setState({
                is_on: this.props.lesson.is_answer_keys_shown
            })
        }
    }

    toggleAnswerKeys() {
        return () => {
            if (this.state.is_on == this.props.lesson.is_answer_keys_shown) {
                this.setState({is_on: !this.state.is_on})

                MindsetsJsSDK().Api.lesson
                    .toggleAnswerKeys(this.props.lesson.id)
                    .then(() => {
                        analytic.log(
                            'lessonToggleAnswerKeys',
                            this.props.lesson.code,
                            !this.props.lesson.is_answer_keys_shown
                        )
                    })
                    .catch((error) => {
                        this.setState({is_on: this.props.lesson.is_answer_keys_shown})
                        console.error(error)
                    });
            }
        }
    }

    renderAsButton() {
        const action = this.state.is_on ? 'Hide' : 'Show';
        const buttonClass = this.state.is_on ? 'fa-eye-slash' : 'fa-eye';

        return (
            <button className="btn btn-on-white" onClick={this.toggleAnswerKeys()}>
                <i className={"fas " + buttonClass}></i>
                <span>{action + ` Answer Keys`}</span>
            </button>
        );
    }

    renderDescription() {
        if (!this.state.is_on) {
            return (
                <div className='answer-keys-toggle-sub-text'>
                    <a
                        href='https://fast.wistia.com/embed/channel/sc32gs6ywb?wchannelid=sc32gs6ywb&wmediaid=wj8qyakg4k'
                        target='_blank'
                    >
                        {`Learn more`}
                    </a>
                </div>
            )
        }

        const getDescription = () => {
            if (this.state.is_on) {
                return 'Yes. Provide students with Answer Keys at the end of the Challenge and Bonus.'
            } else {
                return "No. Don't show students the Answer Keys."
            }
        }

        return (
            <div className='answer-keys-toggle-sub-text'>
                <span>
                    {getDescription()}
                    {` `}
                </span>
                <a
                    href='https://fast.wistia.com/embed/channel/sc32gs6ywb?wchannelid=sc32gs6ywb&wmediaid=wj8qyakg4k'
                    target='_blank'
                >
                    {`Learn more.`}
                </a>
            </div>
        )
    }

    renderAsToggle() {
        return (
            <div className="answer-keys-toggle">
                <div className='flex-row flex-v-center'>
                    <i className="action-icon fas fa-key mr-2"/>
                    <div className="flex-row flex-h-start flex-v-center">
                        <div className='answer-keys-toggle-text'>Show Answer Keys</div>
                    </div>
                    <div className="flex-auto"></div>
                    <Toggle onChange={this.toggleAnswerKeys()} is_on={this.state.is_on}/>
                    <div className="answer-keys-toggle-value">
                        {this.state.is_on ? 'Yes' : 'No'}
                    </div>
                </div>

                {this.renderDescription()}

                {this.renderLinks()}
            </div>
        );
    }

    renderLinks() {
        if (this.props.showLinks && this.state.is_on) {
            return (
                <div className='akt-links'>
                    <div className='akt-links-title'>
                        {`View:`}
                    </div>
                    <div className='akt-links-links'>
                        <a className="underline" href={this.props.answer_key_url} target='_blank'>
                            {`Answer Key`}
                        </a>
                        <span className='mx-2'>|</span>
                        <a className="underline" href={this.props.bonus_answer_key_url} target='_blank'>
                            {`Answer Key - Bonus`}
                        </a>
                    </div>
                </div>
            );
        }

        return null;
    }

    render() {
        if (this.props.answer_key_url && this.props.bonus_answer_key_url) {
            if (this.props.asToggle) {
                return this.renderAsToggle();
            } else {
                return this.renderAsButton();
            }
        }

        return null;
    }
}

ToggleAnswerKeysAction.defaultProps = {
    asToggle: false,
    showLinks: false
}

ToggleAnswerKeysAction.propTypes = {
    lesson: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        is_answer_keys_shown: PropTypes.bool.isRequired
    }).isRequired,
    answer_key_url: PropTypes.string,
    bonus_answer_key_url: PropTypes.string,
    asToggle: PropTypes.bool,
    showLinks: PropTypes.bool
}

export default ToggleAnswerKeysAction;
