import React, {Component} from 'react';
import MindsetsJsSDK from "mindsets-js-sdk";
import Cookie from "mindsets-js-sdk/src/Cookie";

import Announcement from './components/Announcement';

import './announcements.scss';

export default class Announcements extends Component {
    constructor(props) {
        super(props);
        this.state = {
            announcements: []
        }
    }

    componentDidMount() {
        MindsetsJsSDK().Api.announcement
            .index()
            .then(response => this.setState({announcements: response.items}))
            .catch(error => console.error(error));
    }

    onClose() {
        return announcement => {
            let announcements = this.state.announcements;
            const count = Cookie.getCookie('mindsets_announcement_count_' + announcement.id) ? parseInt(Cookie.getCookie('mindsets_announcement_count_' + announcement.id)) : 0;
            if (announcement.dismissable_after && announcement.dismissable_after - 1 > count) {
                Cookie.setCookie(
                    'mindsets_announcement_count_' + announcement.id,
                    count + 1,
                    604800
                );
                announcements.splice(announcements.indexOf(announcement), 1);
                this.setState({announcements});
            } else {
                Cookie.setCookie('mindsets_announcement_' + announcement.id, 'closed', 604800);
                this.setState({});
            }
        }
    }

    render() {
        return (
            <div>
                {this.state.announcements
                    .filter(announcement => Cookie.getCookie('mindsets_announcement_' + announcement.id) !== 'closed')
                    .filter(announcement => {
                        if (!announcement.target) {
                            return true;
                        }

                        const group_keys = Object.keys(announcement.target);
                        if (group_keys.length > 0) {
                            let element_found = false
                            group_keys.map(group_key => {
                                if (Array.isArray(announcement.target[group_key])) {
                                    announcement.target[group_key].map(item_key => {
                                        if (!element_found) {
                                            element_found = !!document.getElementById(item_key);
                                        }
                                    });
                                }
                            });

                            return element_found;
                        }

                        return true;
                    })
                    .map((announcement, index) => {
                        return (
                            <Announcement
                                key={announcement.id}
                                announcement={announcement}
                                index={index + 1}
                                onClose={this.onClose()}
                            />
                        );
                    })}
            </div>
        );
    }
}
