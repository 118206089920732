import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import {filter} from 'lodash';
import MindsetsJsSDK, {LoadingAction, rolesConstants, ErrorMessageAction} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';
import TeacherReportAction from 'common/TeacherReportAction';


class ClassReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lesson: {},
            streams: [],
            challenge_stream_entities: []
        }
    }

    fetchLesson() {
        return MindsetsJsSDK().Api.lesson.get(
            this.props.lesson_code,
            {
                'selectors[0]': 'challenge_code_standards'
            }
        ).then((response) => {
            return new Promise((resolve) => {
                this.setState({
                    lesson: response.lesson
                }, () => resolve(response))
            })
        }).catch((error) => {
            console.error(error)
        })
    }

    fetchChallengeStreamEntities() {
        return MindsetsJsSDK().Api.challengeEntity.index(
            this.props.challenge_version_id
        ).then((response) => {
            return new Promise((resolve) => {
                this.setState({
                    challenge_stream_entities: response.items
                }, () => resolve(response))
            })
        }).catch((error) => {
            console.error(error);
            this.props.dispatch(ErrorMessageAction(error));
        })
    }

    fetchLessonStreams() {
        return MindsetsJsSDK().Api.stream.index({
            'filters[lesson_id][0]': 'eq=' + this.props.lesson_id,
            'selectors[0]': 'user',
            'selectors[1]': 'stream_entities_responses',
            'limit': 0,
        }).then((response) => {
            const student_streams = filter(response.items, (s) => s.persona === rolesConstants.ROLE_STUDENT)
            this.setState({
                streams: student_streams
            })
        }).catch(error => console.error(error))
    }

    onClick() {
        return () => {
            this.props.dispatch(LoadingAction(true, 'Preparing class report...'));
            this.fetchLesson().then(() => {
            }).then(() => {
                return this.fetchChallengeStreamEntities()
            }).then(() => {
                return this.fetchLessonStreams()
            }).then(() => {
                this.props.dispatch(LoadingAction(false))
            })

            analytic.log(
                'samTeacherClassReportDownloaded',
                this.props.lesson_code,
                this.props.challenge_name
            )
        }
    }

    render() {
        if (this.props.challenge_data_version > 0) {
            return (
                <TeacherReportAction
                    lesson={this.state.lesson}
                    challenge_stream_entities={this.state.challenge_stream_entities}
                    streams={this.state.streams}
                >
                    <i className="fas fa-download" onClick={this.onClick()}/>
                </TeacherReportAction>
            )
        }

        return 'N/A';
    }
}

ClassReport.propTypes = {
    lesson_id: PropTypes.number.isRequired,
    lesson_code: PropTypes.string.isRequired,
    challenge_version_id: PropTypes.number.isRequired,
    challenge_name: PropTypes.string.isRequired,
    challenge_data_version: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired
}

export default props => <ClassReport {...props} dispatch={useDispatch()}/>;
