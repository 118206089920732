import React from 'react';

import TemplateTypeA from './TemplateTypeA';

export default function ({dashboard_id, printable}) {
    return (
        <div>
            <TemplateTypeA
                dashboard_id={dashboard_id}
                printable={printable}
            />
        </div>
    );
}