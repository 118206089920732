import React, {Component} from 'react'


class Content4 extends Component {
    render() {
        return (
            <div>
                <h3 className="header-new">
                    {`Module 4: How to facilitate a Mindsets Challenge`}
                </h3>

                <br />

                <div dangerouslySetInnerHTML={{__html: `<div class="wistia_responsive_padding" style="padding:62.5% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/0kbib7s3sy?videoFoam=true" title="Facilitating a Mindsets Challenge.mov Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" allowfullscreen msallowfullscreen width="100%" height="100%"></iframe></div></div><script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>`}}>
                </div>

                <br />

                <h4 className="header-new">
                    {`Gold standard practices during the facilitation`}
                </h4>
                <div>
                    {`Use this list of gold standard practices to facilitate a Mindsets Challenge. `}
                    {`Note that as with any new lesson or tool, your teaching practice will improve with time. `}
                    {`Students will also become familiar with the structure and expectations of Challenges.`}
                </div>
                <div>
                    <br />
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/gold_practice_2.png`}
                        alt="A graphic showing 3 tips to practice during a Mindsets Challenge."
                        title="A graphic showing 3 tips to practice during a Mindsets Challenge."
                    />
                </div>

                <div className="section">
                    <div className="divider" />
                </div>

                <h4 className="header-new">
                    {`Reflection:`}
                </h4>
                <div>
                    <strong>
                        {`How will you facilitate the Challenge you selected in the previous module?`}
                    </strong>
                </div>
                <ul>
                    <li><strong>{`How will you introduce the Challenge? Will you do the suggested warm-up activity or incorporate an engaging teaser or video clip?`}</strong></li>
                    <li><strong>{`At what point during the Challenge will you pause the class for discussion?`}</strong></li>
                    <li><strong>{`Will you project student answers or dashboards to the class for discussion?`}</strong></li>
                </ul>
            </div>
        )
    }
}

export default Content4
