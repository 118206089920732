import React, {Component} from 'react';
import PropTypes  from 'prop-types';

import './text_bubble.scss';


class TextBubble extends Component {
    render() {
        return (
            <div className="text-bubble-container">
                <div className={"text-bubble " + ` text-bubble-${this.props.bubble_background}`}>
                    <div className="text-bubble-content">
                        {this.props.children}
                    </div>

                    <div className={"text-bubble-arrow " + `text-bubble-arrow-${this.props.bubble_direction}`}>
                    </div>
                </div>
            </div>
        )
    }
}

TextBubble.defaultProps = {
    bubble_background: 'blue',
    bubble_direction: 'top'
}

TextBubble.propType = {
    bubble_background: PropTypes.string.isRequired,
    bubble_direction: PropTypes.string.isRequired
}

export default TextBubble
