import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {flatten} from 'lodash';
import ReactTooltip from 'react-tooltip';

import analytic from 'utils/Analytic'


class ProgressBar extends Component {
    componentDidUpdate() {
        ReactTooltip.rebuild()
    }

    renderAvatar() {
        if (this.props.show_avatar && this.props.me) {
            const onClick = () => {
                analytic.log('progressToolBarAvatar')
            };
            return (
                <div className="progress-cell-avatar hide-on-med-and-down">
                    <div className={`cashtivity-icon ${this.props.me.avatar}`} onClick={onClick}/>
                </div>
            )
        }
    }

    renderCell(key, is_bonus, is_done, is_current) {
        const cell_props = {
            key,
            className: '',
            'data-for': 'challenge-progress-bar-tooltip',
            'data-tip': ''
        }

        cell_props.className += ' challenge-progress-cell ';
        cell_props.className += is_bonus ? ' progress-cell-bonus ' : ' progress-cell-page ';
        cell_props.className += is_done ? ' progress-cell-done ' : '';
        cell_props.className += is_current ? ' progress-cell-current ' : '';
        cell_props.className += is_current ? ' flex-6 ' : ' flex-4 ';
        cell_props.className += is_current ? ' flex-row flex-vh-center ' : '';
        cell_props.className += is_current && this.props.current_taller ? ' progress-cell-taller z-depth-1 ' : '';

        cell_props['data-tip'] += key === this.props.current_stream_index ? 'student progress<br>' : ''
        cell_props['data-tip'] += is_current ? 'you are here<br>' : ''

        const onClick = () => {
            analytic.log('progressToolBarBlock')
        };

        return [
            <div {...cell_props} onClick={onClick}>
                {is_current ? this.renderAvatar() : null}
            </div>
            ,
            <div className="flex-1" key={`${key}_divider`}/>
        ]
    }

    renderCells() {
        const cells = []
        const {first_bonus_index, final_index} = this.props.challenge_version
        for (let i = 0; i <= final_index; i++) {
            cells.push(
                this.renderCell(
                    i,
                    i >= first_bonus_index - 1,
                    i <= this.props.current_stream_index,
                    i === this.props.current_card_index
                )
            )
        }

        const flatten_cells = flatten(cells)
        flatten_cells.pop()
        return flatten_cells
    }

    renderTooltip() {
        if (this.props.show_tooltip) {
            return (
                <ReactTooltip
                    id="challenge-progress-bar-tooltip"
                    effect="solid"
                    multiline={true}
                />
            )
        }
    }

    render() {
        return (
            <div className="challenge-progress-bar flex-row">
                {this.renderCells()}

                {this.renderTooltip()}
            </div>
        )
    }
}

ProgressBar.propTypes = {
    challenge_version: PropTypes.shape({
        first_bonus_index: PropTypes.number.isRequired,
        final_index: PropTypes.number.isRequired
    }).isRequired,
    current_card_index: PropTypes.number.isRequired,
    current_stream_index: PropTypes.number.isRequired,
    show_avatar: PropTypes.bool.isRequired,
    current_taller: PropTypes.bool.isRequired,
    show_tooltip: PropTypes.bool.isRequired,
    me: PropTypes.shape({
        avatar: PropTypes.string.isRequired
    })
};

export default props => <ProgressBar {...props} me={useSelector(state => state.me)}/>;
