import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from "react-redux";
import classNames from 'classnames'
import MindsetsJsSDK, {Toggle} from "mindsets-js-sdk";

import analytic from 'utils/Analytic'
import FeatureLock from 'common/membership/FeatureLock'
import {userCan} from 'utils/membership'

import './toggle_is_hidden_action.scss';


class ToggleIsHiddenAction extends Component {
    constructor(props) {
        super(props)
        this.state = {
            is_on: props.lesson.is_hidden
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lesson.is_hidden != this.props.lesson.is_hidden) {
            this.setState({
                is_on: this.props.lesson.is_hidden
            })
        }
    }

    toggleOnOff() {
        return () => {
            if (this.state.is_on == this.props.lesson.is_hidden) {
                this.setState({is_on: !this.state.is_on})

                MindsetsJsSDK().Api.lesson
                    .update(this.props.lesson.id, {is_hidden: !this.props.lesson.is_hidden})
                    .then(() => {
                        analytic.log(
                            'lessonToggleHiddenForStudents',
                            this.props.lesson.code,
                            !this.props.lesson.is_hidden
                        )
                    })
                    .catch((error) => {
                        this.setState({is_on: this.props.lesson.is_hidden})
                        console.error(error)
                    });
            }
        }
    }

    isDisabled() {
        return this.props.is_paid_only && (!this.props.me || !userCan(this.props.me.subscription_status, 'makeLessonHidden'))
    }

    renderLabel() {
        const label = (
            <div className="toggle-label flex-row flex-v-center">
                <i className="toggle-icon fas fa-ban mr-2"/>
                <div>
                    {`Hide Class from students`}
                </div>
            </div>
        )

        if (this.props.is_paid_only && this.props.me) {
            return (
                <FeatureLock
                    enable={!!this.props.me && userCan(this.props.me.subscription_status, 'makeLessonHidden')}
                >
                    {label}
                </FeatureLock>
            )
        } else {
            return label
        }
    }

    renderSwitch() {
        return (
            <Toggle onChange={this.toggleOnOff()} is_on={this.state.is_on} is_disabled={this.isDisabled()}/>
        );
    }

    renderValue() {
        return (
            <div className="toggle-value">
                {this.state.is_on ? 'Yes' : 'No'}
            </div>
        )
    }

    renderDescriptionOn() {
        if (!this.state.is_on) {
            return null
        }

        return (
            <div>
                <span>
                    {`Yes. Students won’t be able to see this class. It is now hidden. `}
                </span>
            </div>
        )
    }

    render() {
        const class_name = classNames(
            'toggle-is-hidden-action is-hidden-toggle',
            {
                'toggle-is-hidden-action-disabled': this.isDisabled()
            }
        )

        return (
            <div className={class_name}>
                <div className='flex-row flex-v-center'>
                    {this.renderLabel()}

                    <div className="flex-auto"></div>

                    {this.renderSwitch()}

                    {this.renderValue()}
                </div>

                <div className="toggle-description">
                    {this.renderDescriptionOn()}
                </div>
            </div>
        )
    }
}

ToggleIsHiddenAction.defaultProps = {
    is_paid_only: false
}

ToggleIsHiddenAction.propTypes = {
    lesson: PropTypes.object.isRequired,
    is_paid_only: PropTypes.bool,
    me: PropTypes.shape({
        subscription_status: PropTypes.object.isRequired
    })
}

export default props => <ToggleIsHiddenAction {...props} me={useSelector(state => state.me)}/>
