import React, {Component} from 'react';
import PropTypes from 'prop-types';
import MindsetsJsSDK, {Toggle} from "mindsets-js-sdk";
import ReactTooltip from "react-tooltip";

import analytic from 'utils/Analytic';

import './toggle_lesson_mode_action.scss';

class ToggleLessonModeAction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: props.lesson.mode
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lesson.mode !== this.props.lesson.mode) {
            this.setState({
                mode: this.props.lesson.mode
            });
        }
    }

    changeMode(mode) {
        return () => {
            const oldMode = this.state.mode;

            if (mode !== oldMode) {
                this.setState({mode}, () => {
                    MindsetsJsSDK().Api.lesson
                        .updateMode(this.props.lesson.id, mode)
                        .then(() => {
                            analytic.log(
                                'toggleTeachingMode',
                                this.props.lesson.code,
                                this.props.lesson.mode
                            );
                        })
                        .catch((error) => {
                            this.setState({mode: oldMode});
                            console.error(error);
                        });
                });
            }
        }
    }

    renderAsToggle() {
        return (
            <div className="lesson-mode-toggle toggle">
                <div className='flex-row flex-v-center'>
                    <i className="action-icon fas fa-chalkboard-teacher mr-2"/>
                    <div className="flex-row flex-h-start flex-v-center">
                        <div className='answer-keys-toggle-text'>Enable Teacher Paced mode</div>
                    </div>
                    <div className="flex-auto"/>
                    <Toggle onChange={this.changeMode(this.state.mode === 'teacher' ? 'student' : 'teacher')} is_on={this.state.mode === 'teacher'}/>
                    <div className="answer-keys-toggle-value">
                        {this.state.mode === 'teacher' ? 'Yes' : 'No'}
                    </div>
                </div>
            </div>
        );
    }

    renderAsButtons() {
        return (
            <div className="lesson-mode-toggle buttons">
                <div className='flex-row flex-box-5-5 flex-wrap'>
                    <button className='btn btn-dark no-wrap' disabled={this.state.mode === 'student'}
                            onClick={this.changeMode('student')}>Student Paced mode
                    </button>
                    <button className='btn btn-dark no-wrap' disabled={this.state.mode === 'teacher'}
                            onClick={this.changeMode('teacher')}>Teacher Paced mode
                    </button>
                </div>
            </div>
        );
    }

    renderAsRadio() {
        return (
            <div className="lesson-mode-toggle radio">
                <form action="#">
                    <p>
                        <input name="teaching-mode" className='with-gap' type="radio" id="spm"
                               onChange={this.changeMode('student')} checked={this.state.mode === 'student'}/>
                        <label htmlFor="spm" className='radio-label'>Student Paced mode</label>
                    </p>
                    <p>
                        <input name="teaching-mode" className='with-gap' type="radio" id="tdm"
                               onChange={this.changeMode('teacher')} checked={this.state.mode === 'teacher'}/>
                        <label htmlFor="tdm" className='radio-label'>Teacher Paced mode</label>
                    </p>
                </form>
            </div>
        );
    }

    renderAsTicks() {
        return (
            <div className="lesson-mode-toggle ticks flex-box-5-5">
                {this.props.with_heading ? <h5 className='header-new'>Teaching mode</h5> : null}
                <div>
                    <div className='flex-row pointer flex-box-0-5 flex-v-center' onClick={this.changeMode('student')}>
                        <i className={"fas fa-check " + (this.state.mode === 'student' ? 'selected' : '')}/>
                        <p className='label'>Student Paced</p>
                    </div>
                </div>
                <div>
                    <div className='flex-row pointer flex-box-0-5 flex-v-center' onClick={this.changeMode('teacher')}>
                        <i className={"fas fa-check " + (this.state.mode === 'teacher' ? 'selected' : '')}/>
                        <p className='label'>Teacher Paced</p>
                    </div>
                </div>
            </div>
        );
    }

    renderAsButtonset() {
        return (
            <div className='buttonset'>
                <button
                    className={'btn btn-flat ' + (this.state.mode === 'student' ? 'active' : '')}
                    onClick={this.changeMode('student')}
                    data-tip='Students control which page they are on'
                >
                    <img
                        className='mr-1'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/lesson_mode/student_` + (this.state.mode === 'student' ? 'teal_inverted' : 'black') + '.svg'}
                        alt='Student Paced mode'
                    />
                    <p>Student Paced</p>
                </button>
                <button
                    className={'btn btn-flat ' + (this.state.mode === 'teacher' ? 'active' : '')}
                    onClick={this.changeMode('teacher')}
                    data-tip='You control which page all students are on'
                >
                    <img
                        className='mr-1'
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/lesson_mode/teacher_` + (this.state.mode === 'teacher' ? 'purple_inverted' : 'black') + '.svg'}
                        alt='Teacher Paced mode'
                    />
                    <p>Teacher Paced</p>
                </button>
                <ReactTooltip/>
            </div>
        );
    }

    renderAsTiles() {
        return (
            <div className='lesson-mode-toggle mode-tiles flex-row flex-box-0-5'>
                <div className={'mode-tile-border ' + (this.state.mode === 'student' ? 'active' : '')}>
                    <a
                        href={'!#'}
                        className={'mode-tile ' + (this.state.mode === 'student' ? 'active' : '')}
                        onClick={event => {
                            event.preventDefault();
                            this.changeMode('student')();
                        }}
                        data-tip='Students control which page they are on'
                    >
                        <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_login_student.svg`} alt='Student Paced mode'/>
                        <p className="mode-tile-name">Student Paced</p>
                    </a>
                </div>
                <div className={'mode-tile-border ' + (this.state.mode === 'teacher' ? 'active' : '')}>
                    <a
                        href={'!#'}
                        className={'mode-tile ' + (this.state.mode === 'teacher' ? 'active' : '')}
                        onClick={event => {
                            event.preventDefault();
                            this.changeMode('teacher')();
                        }}
                        data-tip='You control which page all students are on'
                    >
                        <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_login_teacher.svg`} alt='Teacher Paced mode'/>
                        <p className="mode-tile-name">Teacher Paced</p>
                    </a>
                </div>
                <ReactTooltip/>
            </div>
        );
    }

    render() {
        switch (this.props.mode) {
            case "buttons":
                return this.renderAsButtons();
            case "radio":
                return this.renderAsRadio();
            case "ticks":
                return this.renderAsTicks();
            case "buttonset":
                return this.renderAsButtonset();
            case "tiles":
                return this.renderAsTiles();
            case "toggle":
                return this.renderAsToggle();
            default:
                return this.renderAsToggle();
        }
    }
}

ToggleLessonModeAction.defaultProps = {
    mode: 'ticks',
    with_heading: false
}

ToggleLessonModeAction.propTypes = {
    lesson: PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        mode: PropTypes.string.isRequired
    }).isRequired,
    mode: PropTypes.oneOf(['toggle', 'buttons', 'radio', 'ticks', 'buttonset', 'tiles']),
    with_heading: PropTypes.bool
}

export default ToggleLessonModeAction;
