import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get, isEmpty} from 'lodash'
import classNames from 'classnames'
import ReactTooltip from 'react-tooltip'

import analytic from 'utils/Analytic'


class AnswerKeyButton extends Component {
    componentDidMount() {
        if (this.button_ref) {
            ReactTooltip.show(this.button_ref)
        }
    }

    getIsChallengePreview() {
        return isEmpty(this.props.lesson) && this.props.is_preview
    }

    getButtonClassName() {
        const btn_class_name = classNames('btn btn-large', {
            'btn-white': get(this.props.lesson, 'is_answer_keys_shown'),
            'btn-maybe': isEmpty(this.props.lesson) && this.props.is_preview
        })
        return btn_class_name
    }

    getTooltipProps() {
        if (this.getIsChallengePreview()) {
            return {
                'data-for': 'answer-key-button-tooltip',
                'data-tip': 'You can choose to have the Answer Key on or off'
            }
        } else {
            return {}
        }
    }

    getAnswerKeyUrl() {
        if (this.props.is_bonus) {
            return get(this.props.challenge_version, 'bonus_answer_key_url')
        } else {
            return get(this.props.challenge_version, 'answer_key_url')
        }
    }

    getButtonLabel() {
        if (this.props.is_bonus) {
            return `Download Answer Key - Bonus`
        } else {
            return `Download Answer Key`
        }
    }

    onClick() {
        return () => {
            const event_name = this.props.is_bonus ? 'downloadBonusAnswerKey' : 'downloadAnswerKey'
            analytic.log(event_name, {
                id: this.props.challenge_version.id,
                name: this.props.challenge_version.name,
                code: this.props.challenge_version.challenge_code.code
            }, get(this.props.lesson, 'id'))
        }
    }

    renderTooltip() {
        if (this.getIsChallengePreview()) {
            return (
                <ReactTooltip
                    id="answer-key-button-tooltip"
                    effect="solid"
                    multiline={true}
                    place='top'
                    delayHide={99999}
                />
            )
        }

        return null
    }

    render() {
        if (!this.props.challenge_version.answer_key_url) {
            return null
        }

        const is_answer_keys_shown = get(this.props.lesson, 'is_answer_keys_shown')
        if (!isEmpty(this.props.lesson) && !is_answer_keys_shown) {
            return null
        }

        return (
            <div className="answer-key-button">
                <a
                    className={this.getButtonClassName()}
                    href={this.getAnswerKeyUrl()}
                    target="_blank"
                    onClick={this.onClick()}
                    {...this.getTooltipProps()}
                    ref={(button_ref) => this.button_ref = button_ref}
                >
                    <i className="fas fa-download"></i>
                    <span className='no-wrap'>
                        {this.getButtonLabel()}
                    </span>
                </a>

                {this.renderTooltip()}
            </div>
        )
    }
}

AnswerKeyButton.propTypes = {
    challenge_version: PropTypes.shape({
        answer_key_url: PropTypes.string.isRequired,
        bonus_answer_key_url: PropTypes.string.isRequired,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired
        }).isRequired,
    }).isRequired,
    lesson: PropTypes.object,
    is_preview: PropTypes.bool,
    is_bonus: PropTypes.bool.isRequired
}

export default AnswerKeyButton
