import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {sortBy, reverse, filter} from 'lodash'

import Teacher from './Teacher'


class School extends Component {
    renderSchool() {
        return (
            <div className="sam-t-school">
                {`School: ${this.props.school.name}`}
            </div>
        )
    }

    renderEmptyState() {
        if (this.props.school.teachers.length > 0) {
            return null
        }

        return (
            <div className="sam-t-teacher-empty">
                {`Currently no teacher.`}
            </div>
        )
    }

    renderTeacherheader() {
        if (this.props.school.teachers.length <= 0) {
            return null
        }

        return (
            <div className="flex-row sam-t-teacher-header">
                <div className="sam-t-teacher-name">
                    {`Teacher`}
                </div>
                <div className="sam-t-teacher-classes">
                    {`# of Classes`}
                </div>
                <div className="sam-t-teacher-students">
                    {`# of Students`}
                </div>
                <div className="sam-t-teacher-completion flex-auto">
                    {`Student Engagement`}
                </div>
            </div>
        )
    }

    renderTeachers() {
        const sorted_teachers = reverse(
            sortBy(
                filter(this.props.school.teachers, ((t) => t.no_of_classes > 0)),
                ['no_of_classes', 'no_of_student_streams']
            )
        )
        const render_teachers = sorted_teachers.map((teacher) => {
            return (
                <Teacher
                    key={`t-${teacher.id}`}
                    teacher={teacher}
                />
            )
        })

        return render_teachers
    }

    render() {
        return (
            <div className="sam-t-one-school">
                {this.renderSchool()}

                {this.renderEmptyState()}

                {this.renderTeacherheader()}

                {this.renderTeachers()}
            </div>
        )
    }
}

School.propTypes = {
    school: PropTypes.object.isRequired
}

export default School
