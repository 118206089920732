import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {LoadingAction, CustomDropdown} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';
import {userCan} from 'utils/membership';
import DownloadCSV from './DownloadCSV';
import ExportToGoogleDrive from './ExportToGoogleDrive';
import FeatureLock from 'common/membership/FeatureLock';

function TeacherReportAction(
    {
        children,
        lesson,
        challenge_stream_entities,
        selected_stream_id,
        streams,
        callBack,
        dropdownClass = ''
    }
) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);

    function onDownloadCSVClick(e) {
        e.preventDefault();
        e.stopPropagation();

        if (typeof callBack === 'function') {
            callBack();
        }

        if (canCreate()) {
            (new DownloadCSV()).execute(
                getReportTitle(),
                lesson,
                getStreams(),
                challenge_stream_entities
            );
            analytic.log('downloadReport', lesson.code);
        } else {
            if (confirm("Upgrade to unlock this feature.\n Would you like to be redirected to a pricing page?")) {
                window.open(process.env.PUBLIC_PRICING_URL, '_blank');
            }
        }
    }

    function onExportToSheetsGoogleClick(e) {
        e.preventDefault();
        e.stopPropagation();

        if (typeof callBack === 'function') {
            callBack();
        }

        if (canCreate()) {
            dispatch(LoadingAction(true, 'Exporting...'));
            (new ExportToGoogleDrive()).execute(
                getReportTitle(),
                lesson,
                getStreams(),
                challenge_stream_entities
            );
            analytic.log('exportGoogleReport', lesson.code);
        } else {
            if (confirm("Upgrade to unlock this feature.\n Would you like to be redirected to a pricing page?")) {
                window.open(process.env.PUBLIC_PRICING_URL, '_blank');
            }
        }
    }

    function getStreams() {
        if (selected_stream_id) {
            return streams.filter(stream => stream.id === selected_stream_id);
        } else {
            return streams;
        }
    }

    function getReportTitle() {
        return 'Mindsets ' + lesson.challenge_version.name + ' ' + lesson.code + ' ' + moment().format('MMM DD, YYYY');
    }

    function canCreate() {
        return !!me && userCan(me.subscription_status, 'download_class_report')
    }

    function renderDropdownTrigger() {
        return (
            <FeatureLock
                enable={canCreate()}
                message={'unlock this report'}
            >
                {children}
            </FeatureLock>
        );
    }

    return (
        <CustomDropdown
            trigger={renderDropdownTrigger()}
            no_wrap={true}
            items={{
                csv: <a href={"#!"} onClick={onDownloadCSVClick}>Download CSV file</a>,
                google: <a href={"#!"} onClick={onExportToSheetsGoogleClick}>Export to Google Sheets</a>
            }}
            content_class={dropdownClass}
            use_arrow={false}
        />
    );
}

TeacherReportAction.propTypes = {
    lesson: PropTypes.shape({
        id: PropTypes.number,
        user_id: PropTypes.number,
        code: PropTypes.string,
        is_answer_keys_shown: PropTypes.bool,
        challenge_version: PropTypes.shape({
            name: PropTypes.string.isRequired
        })
    }).isRequired,
    challenge_stream_entities: PropTypes.array.isRequired,
    streams: PropTypes.array.isRequired,
    callBack: PropTypes.func,
    selected_stream_id: PropTypes.number,
    dropdownClass: PropTypes.string
};

export default TeacherReportAction;
