import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Content0 from './Content0'
import Content1 from './Content1'
import Content2 from './Content2'
import Content3 from './Content3'
import Content4 from './Content4'
import Content5 from './Content5'
import Content6 from './Content6'

const CONTENT_CLASSES = [Content0, Content1, Content2, Content3, Content4, Content5, Content6]


class Content extends Component {
    renderContent() {
        const ContentClass = CONTENT_CLASSES[this.props.selected_item]
        return (
            <ContentClass
                teacher_pd_code={this.props.teacher_pd_code}
            />
        )
    }

    render() {
        return (
            <div className="teacher-pd-content">
                {this.renderContent()}
            </div>
        )
    }
}

Content.propTypes = {
    selected_item: PropTypes.number.isRequired,
    teacher_pd_code: PropTypes.string,
}

export default Content
