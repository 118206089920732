import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams, useSearchParams, useNavigate} from "react-router-dom";
import {filterConstants} from "mindsets-js-sdk";

import {
    LoadInsightsDashboardAction
} from "../../actions/performance_actions";
import IndexPrintable from './indexPrintable';
import Filters from "./Filters";
import Template from './Template';

import "./insights-dashboard.scss";

export default function ({printable}) {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const dashboard = useSelector(state => state.insights_dashboard);
    const dashboard_filters = useSelector(state => state.dashboard_filters);
    const [searchParams, setSearchParams] = useSearchParams();
    const district_id = !isNaN(parseInt(searchParams.get('district_id'))) ? parseInt(searchParams.get('district_id')) : null;
    const me = useSelector(state => state.me);

    useEffect(() => {
        if (me) {
            if (!Object.keys(dashboard).length || dashboard.id !== parseInt(params.dashboard_id)) {
                const options = {};
                if (me.is_dashboard_super_admin && district_id) {
                    options['filters[district_id][0]'] = filterConstants.EXPRESSION_EQUAL + '=' + district_id;
                }
                dispatch(LoadInsightsDashboardAction(params.dashboard_id, options));
            }
        }
    }, [me]);

    if (!Object.keys(dashboard).length || dashboard.id !== parseInt(params.dashboard_id)) {
        return null;
    }

    const renderPrintable = () => {
        const school_id = dashboard_filters.school_id;
        const student_id = dashboard_filters.student_id;

        if (school_id.length > 1 || student_id.length != 1) {
            return null;
        }

        const onClick = () => {
            const query_string = searchParams.toString();
            const url = `/insights/${params.dashboard_id}/printable` + (query_string ? `?${query_string}` : '');
            navigate(url);
        }

        return (
            <button
                className="btn btn-on-white"
                onClick={onClick}
            >
                {`Print/PDF`}
            </button>
        );
    }

    if (printable) {
        return (
            <IndexPrintable
                dashboard_id={params.dashboard_id}
                printable={printable}
            />
        );
    }

    return (
        <div id='insights-dashboard'>
            <div className="flex-row flex-v-center flex-between">
                <div className="section">
                    <h3>{dashboard.title}</h3>
                </div>

                <div>
                    {renderPrintable()}
                </div>
            </div>

            <Filters/>

            <Template
                dashboard_id={params.dashboard_id}
            />
        </div>
    );
}
