import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from "react-tooltip";

class ViewModeSwitcher extends Component {
    render() {
        return (
            <div className='mode-switcher'>
                <label>View</label>
                <a
                    href='#' onClick={event => {
                    event.preventDefault();
                    if (this.props.mode !== 'grid') {
                    }
                    this.props.onViewModeChange(this.props.mode === 'grid' ? 'list' : 'grid');
                }}
                    className='mode-switcher-button flex-row flex-v-center'
                    data-tip={this.props.mode === 'grid' ? 'List view' : 'Grid view'}
                >
                    <i className={'fas fa-lg ' + (this.props.mode === 'grid' ? 'fa-th-list' : 'fa-th-large')}/>
                </a>
                <ReactTooltip/>
            </div>
        );
    }
}

ViewModeSwitcher.propTypes = {
    mode: PropTypes.string.isRequired,
    onViewModeChange: PropTypes.func.isRequired
}

export default ViewModeSwitcher;
