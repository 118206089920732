import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {DateRangeSelector, Checkbox, CustomDropdown} from "mindsets-js-sdk";

export const FILTER_COOKIE_NAME = 'mindsets_lesson_filter_'

class LessonFilter extends Component {
    renderHideDone() {
        return (
            <div className='no-wrap'>
                <Checkbox
                    toggle={() => this.props.updateState('hide_done', !this.props.hide_done)}
                    checked={true === this.props.hide_done}
                    label='Hide marked as done'
                />
            </div>
        );
    }

    render() {
        return (
            <div className="flex-row flex-gap-10 flex-wrap flex-v-center flex-grow-1">
                <DateRangeSelector
                    from={this.props.from}
                    till={this.props.till}
                    onUpdate={data => Object.keys(data).map(key => this.props.updateState(key, data[key]))}
                    as={'selector'}
                    with_label={true}
                    label='Date created'
                    key={this.props.list_name + '-date-selector'}
                />
                {/*<button className='btn btn-on-white no-wrap' onClick={event => {*/}
                {/*    event.preventDefault();*/}
                {/*    if (this.props.limit) {*/}
                {/*        this.props.updateState('limit', null);*/}
                {/*    } else {*/}
                {/*        this.props.onOrderChange('created_at:desc');*/}
                {/*        this.props.updateState('range', null);*/}
                {/*        this.props.updateState('from', null);*/}
                {/*        this.props.updateState('till', null);*/}
                {/*        this.props.updateState('limit', 5);*/}
                {/*    }*/}
                {/*}}>{this.props.limit ? 'Show all' : 'Show last 5'}</button>*/}
                {/*<div className='no-wrap'>*/}
                {/*    <Checkbox*/}
                {/*        toggle={() => {*/}
                {/*            if (this.props.limit) {*/}
                {/*                this.props.updateState('limit', null);*/}
                {/*            } else {*/}
                {/*                this.props.onOrderChange('created_at:desc');*/}
                {/*                this.props.updateState('range', null);*/}
                {/*                this.props.updateState('from', null);*/}
                {/*                this.props.updateState('till', null);*/}
                {/*                this.props.updateState('limit', 5);*/}
                {/*            }*/}
                {/*        }}*/}
                {/*        checked={!!this.props.limit}*/}
                {/*    >Show last 5</Checkbox>*/}
                {/*</div>*/}
                {/*<div className='no-wrap'>*/}
                {/*    <Checkbox*/}
                {/*        toggle={this.props.onShowHiddenToggle}*/}
                {/*        checked={true === this.props.show_hidden}*/}
                {/*    >Include Classes hidden from students</Checkbox>*/}
                {/*</div>*/}
                <div>
                    <label>Order by</label>
                    <CustomDropdown
                        id={this.props.list_name + '-order_by'}
                        items={{
                            newest: <div className='order-by-item' onClick={() => this.props.onOrderChange('created_at:desc')}>Newest first</div>,
                            oldest: <div className='order-by-item' onClick={() => this.props.onOrderChange('created_at:asc')}>Oldest first</div>,
                            class_name: <div className='order-by-item' onClick={() => this.props.onOrderChange('name:asc')}>Class Name A-Z</div>,
                            challenge_name: <div className='order-by-item' onClick={() => this.props.onOrderChange('challenge:asc')}>Challenge Name A-Z</div>,
                        }}
                        trigger={(
                            <div>{this.props.order === 'created_at:desc' ? 'Newest first' : (this.props.order === 'created_at:asc' ? 'Oldest first' : (this.props.order === 'name:asc' ? 'Class Name A-Z' : 'Challenge Name A-Z'))}</div>)}
                    />
                </div>
                {/*{this.renderHideDone()}*/}
            </div>
        );
    }
}

LessonFilter.propTypes = {
    order: PropTypes.string.isRequired,
    limit: PropTypes.number,
    hide_done: PropTypes.bool.isRequired,
    onOrderChange: PropTypes.func.isRequired,
    updateState: PropTypes.func.isRequired,
    list_name: PropTypes.string.isRequired,
    from: PropTypes.number,
    till: PropTypes.number
}

export default LessonFilter;
