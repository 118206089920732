import React, {Component} from 'react'
import PropTypes from 'prop-types'


class HTMLContent extends Component {
    refAndMore() {
        return (div) => {
            if (div) {
                div.innerHTML = this.props.html_str
            }
        }
    }

    render() {
        return (
            <div
                className={this.props.className}
                ref={this.refAndMore()}
            >
            </div>
        )
    }
}

HTMLContent.propTypes = {
    className: PropTypes.string,
    html_str: PropTypes.string.isRequired
}

export default HTMLContent
