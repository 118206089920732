import React, {Component} from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import './engagement_stage_legend.scss';


class EngagementStageLegend extends Component {
    renderStage(n, label, tip) {
        const class_name = classNames('c-s-l-stage', `c-s-l-stage-${n}`)
        return (
            <div
                className={class_name}
                data-tip={tip}
            >
                <div className="flex-row flex-v-center">
                    <div className="c-s-l-stage-box">
                    </div>
                    <div className="c-s-l-stage-text">
                        {label}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-stage-legend">
                <div className="challenge-stage-legend-title">
                    {`Engagement Stages:`}
                </div>

                <ReactTooltip
                    effect="solid"
                />

                <div className="flex-row flex-v-center flex-wrap flex-box-5-5">
                    {this.renderStage(0, 'Started', "% of students below page 5 of Challenge")}
                    {this.renderStage(1, 'Underway', "% of Students between page 5 and Nearly Completed page of Challenge")}
                    {this.renderStage(2, 'Nearly Completed', "% of Students that reached one page before Completion")}
                    {this.renderStage(3, 'Completed', "% of Students that Completed Challenge")}
                    {this.renderStage(4, 'Bonus Completed', "% of Students that Completed Bonus section")}
                </div>
            </div>
        )
    }
}

export default EngagementStageLegend
