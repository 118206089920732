import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Draggable} from "react-beautiful-dnd"
import {get} from 'lodash'

import {processStringContent} from 'utils/content'


class AnswerOption extends Component {
    getDraggableStyle(default_style) {
        const color_light = get(this.props.answer_group, 'color_light')
        const color_dark = get(this.props.answer_group, 'color_dark')
        return {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "10px",
            minHeight: "50px",
            minWidth: "50px",
            background: color_light,
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: color_dark,
            borderRadius: "4px",
            ...get(this.props.answer_group, 'draggable_style'),
            ...default_style,
            ...this.props.extra_style
        }
    }

    renderOption() {
        return (
            <Draggable
                draggableId={this.props.option.dnd_id}
                index={this.props.option_index}
                type={this.props.answer_group.dnd_type}
                isDragDisabled={this.props.is_preview}
            >
                {(provided, snapshot) => (
                    <div
                        className="dnd-option"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={this.getDraggableStyle(provided.draggableProps.style)}
                    >
                        {processStringContent(this.props.option.html)}

                        <div className="dnd-option-layer">
                        </div>
                    </div>
                )}
            </Draggable>
        )
    }

    renderAnswer() {
        const showRemoveIcon = () => {
            if (!this.props.is_preview) {
                return (
                    <i
                        className="dnd-answer-one-remove far fa-times-circle"
                        onClick={this.props.onRemove}
                    >
                    </i>
                )
            }
        }

        return (
            <div
                className="dnd-answer-one"
                style={this.getDraggableStyle({})}
            >
                {showRemoveIcon()}

                {processStringContent(this.props.option.html)}
            </div>
        )
    }

    render() {
        if (this.props.is_answer) {
            return this.renderAnswer()
        } else {
            return this.renderOption()
        }
    }
}

AnswerOption.defaultProps = {
    onRemove: () => {}
}

AnswerOption.propTypes = {
    // is_answer = ture if this is for answer, is_answer = false for option
    is_answer: PropTypes.bool.isRequired,
    is_preview: PropTypes.bool.isRequired,
    answer_group: PropTypes.object.isRequired,
    option: PropTypes.object.isRequired,
    option_index: PropTypes.number.isRequired,
    extra_style: PropTypes.object,
    onRemove: PropTypes.func
}

export default AnswerOption
