import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {seOldToNew} from 'utils/challenge/dataModel'
import {getCardClass} from './utils'


class Card extends Component {
    renderContent() {
        const se_new = seOldToNew(this.props.challenge_version, this.props.stream_entity)
        const CardClass = getCardClass(se_new)
        return (
            <CardClass
                challenge_version={this.props.challenge_version}
                stream_entity={se_new}
                lesson={this.props.lesson}
                stream_id={this.props.stream_id}
                is_preview={this.props.is_preview}
                is_latest={this.props.is_latest}
                is_active={this.props.is_active}
                refComponent={this.props.refComponent}
            />
        )
    }

    render() {
        return (
            <div className="challenge-card" key={this.props.stream_entity.id}>
                {this.renderContent()}
            </div>
        )
    }
}

Card.defaultProps = {
    is_preview: false,
    is_latest: false,
    lesson: {}
}

Card.propTypes = {
    challenge_version: PropTypes.object.isRequired,
    stream_entity: PropTypes.shape({
        id: PropTypes.number.isRequired
    }).isRequired,
    lesson: PropTypes.object,
    stream_id: PropTypes.number,
    is_preview: PropTypes.bool,
    is_latest: PropTypes.bool,
    is_active: PropTypes.bool,
    refComponent: PropTypes.func
}

export default Card
