import React, {Component} from 'react'
import PropTypes from 'prop-types'

class Email extends Component {
    render() {
        return (
            <div className='email-list-item-wrapper'>
                <div className='email-list-item'>
                    <span className='email-list-item-email'>{this.props.email}</span>
                    <span className='email-list-item-email-close' onClick={this.props.removeUser}>
                        <i className="far fa-times-circle"/>
                    </span>
                </div>
            </div>
        );
    }
}

Email.propTypes = {
    email: PropTypes.string.isRequired,
    removeUser: PropTypes.func.isRequired
};

export default Email;
