import mixpanel from 'mixpanel-browser'

import analyticTeacherDashboard from './analyticTeacherDashboard'
import analyticChallengePreview from './analyticChallengePreview'
import analyticTeacherLessons from './analyticTeacherLessons'
import analyticTeacherFacilitation from './analyticTeacherFacilitation'
import analyticStudentStream from './analyticStudentStream'
import analyticUserGeneral from './analyticUserGeneral'
import analyticChallengeGeneral from './analyticChallengeGeneral'
import analyticHelperGeneral from './analyticHelperGeneral'
import analyticTeacherLesson from './analyticTeacherLesson'
import analyticTeacherClassSetup from './analyticTeacherClassSetup'
import analyticSocialSharing from './analyticSocialSharing'
import analyticViewAnswer from './analyticViewAnswer'
import analyticMicrosoftTeams from './analyticMicrosoftTeams'
import analyticSAMByTeacher from './analyticSAMByTeacher'
import analyticPricing from './analyticPricing'
import analyticWowMoments from './analyticWowMoments'
import analyticProjectExtensions from './analyticProjectExtensions'


class Analytic {
    init(user_id = null) {
        if (process.env.PUBLIC_MIXPANEL_ON && !this.mixpanel) {
            this.mixpanel = mixpanel
            this.mixpanel.init(process.env.PUBLIC_MIXPANEL_TOKEN)
        }

        if (user_id) {
            this.identify(user_id)
        }
    }

    log(func, ...data) {
        if (process.env.PUBLIC_MIXPANEL_ON) {
            try {
                this[func](...data)
            } catch (error) {
                console.error(error)
            }
        }
    }

    identify(id) {
        if (process.env.PUBLIC_MIXPANEL_ON) this.mixpanel.identify(id)
    }

    setUserData(data) {
        if (process.env.PUBLIC_MIXPANEL_ON) this.mixpanel.people.set(data)
    }

    setUserDataOnce(name, value) {
        if (process.env.PUBLIC_MIXPANEL_ON) this.mixpanel.people.set_once(name, value)
    }

    increment(name, value = 1) {
        if (process.env.PUBLIC_MIXPANEL_ON) this.mixpanel.people.increment(name, value)
    }

    track(event_name, data = {}) {
        if (process.env.PUBLIC_MIXPANEL_ON) this.mixpanel.track(event_name, data)
    }

    reset() {
        if (process.env.PUBLIC_MIXPANEL_ON) this.mixpanel.reset()
    }

    obfuscateEmail(email) {
        return email.replace(/(.{2})(.*)(?=@)/,
            function (gp1, gp2, gp3) {
                for (let i = 0; i < gp3.length; i++) {
                    gp2 += "*";
                }
                return gp2;
            });
    }
}

analyticTeacherDashboard(Analytic)
analyticChallengePreview(Analytic)
analyticTeacherLessons(Analytic)
analyticTeacherFacilitation(Analytic)
analyticStudentStream(Analytic)
analyticUserGeneral(Analytic)
analyticChallengeGeneral(Analytic)
analyticHelperGeneral(Analytic)
analyticTeacherLesson(Analytic)
analyticTeacherClassSetup(Analytic)
analyticSocialSharing(Analytic)
analyticViewAnswer(Analytic)
analyticMicrosoftTeams(Analytic)
analyticSAMByTeacher(Analytic)
analyticPricing(Analytic)
analyticWowMoments(Analytic)
analyticProjectExtensions(Analytic)

const analytic = new Analytic

export default analytic
