import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";
import {sortBy, reverse} from 'lodash';

import ChallengeTile from 'common/ui/ChallengeTile';


class AChallenge extends Component {
    onClick() {
        return () => {
            this.props.onSelectChallenge(this.props.challenge_version.challenge_code.code)
        }
    }

    renderDivider() {
        if (!this.props.is_selected) {
            return null
        }

        return (
            <div className="student-a-challenge-divider hide-on-small-only">
            </div>
        )
    }

    renderCodes() {
        if (!this.props.is_selected) {
            return null
        }

        const sorted = reverse(sortBy(this.props.challenge_version.lessons, 'created_at'))

        const codes = sorted.map((lesson) => {
            const onClick = () => {
                this.props.navigate(`/hello/${lesson.streams[0].code}`);
            }
            return (
                <div key={lesson.code} className="student-a-code" onClick={onClick}>
                    <div className="flex-row flex-wrap flex-box-1-2">
                        <div><i className="fas fa-angle-right"/></div>
                        <div className="student-a-code-code">
                            <strong>{lesson.code.toUpperCase()}</strong>
                        </div>
                        <div>{` | `}</div>
                        <div className="student-a-code-name">{lesson.name}</div>
                    </div>
                </div>
            )
        })

        return (
            <div className="student-a-challenge-codes">
                <h5 className="header-new">
                    {`Which Mindsets Code?`}
                </h5>

                {codes}
            </div>
        )
    }

    render() {
        if (this.props.challenge_version.lessons.length <= 0) {
            return null
        }

        return (
            <div className="student-dashboard-a-challenge">
                <div className="flex-row-m flex-wrap">
                    <div>
                        <ChallengeTile
                            challenge_version={this.props.challenge_version}
                            show_kid={true}
                            description={``}
                            button_label={this.props.is_selected ? 'Cancel' : `View All`}
                            buttonOnClick={this.onClick()}
                        />
                    </div>

                    <div>
                        {this.renderDivider()}
                    </div>

                    <div>
                        {this.renderCodes()}
                    </div>
                </div>
            </div>
        )
    }
}

AChallenge.propTypes = {
    challenge_version: PropTypes.shape({
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired
        }).isRequired,
        lessons: PropTypes.array.isRequired
    }).isRequired,
    is_selected: PropTypes.bool.isRequired,
    onSelectChallenge: PropTypes.func.isRequired,
    navigate: PropTypes.func.isRequired
}

export default props => (
    <AChallenge
        {...props}
        navigate={useNavigate()}
    />
);
