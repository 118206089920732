import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

import analytic from 'utils/Analytic';
import PreviewLayerReminder from './PreviewLayerReminder';
import PreviewLayerFinished from './PreviewLayerFinished';
import FeatureLock from 'common/membership/FeatureLock';
import {userCan} from 'utils/membership';

function PreviewFrame({goto, children}) {
    const me = useSelector(state => state.me);
    const challenge_version = useSelector(state => state.challenge_version);
    const stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const current_index = useSelector(state => state.challenge_preview_current_index);
    const show_reminder = useSelector(state => state.challenge_preview_show_reminder);

    function isFirst() {
        return current_index <= 0
    }

    function isLast() {
        return current_index > 0 && current_index >= stream_entities.length - 1
    }

    function goPrevious() {
        const to_index = current_index - 1;
        goto(to_index);
        analytic.log('previewPrevious', {
            name: challenge_version.name,
            code: challenge_version.challenge_code.code
        }, to_index);
    }

    function goNext() {
        const to_index = current_index + 1;
        goto(to_index);
        analytic.log('previewNext', {
            name: challenge_version.name,
            code: challenge_version.challenge_code.code
        }, to_index);
    }

    function renderButtonPrevious() {
        if (isFirst()) {
            return null;
        }

        return (
            <div className="challenge-preview-previous">
                <button className="btn btn-large btn-challenge" onClick={goPrevious}>
                    <i className="fas fa-chevron-left"/>
                </button>
            </div>
        );
    }

    function renderButtonNext() {
        if (isLast()) {
            return null;
        }

        const can_preview_full = !!me && userCan(me.subscription_status, 'preview_full_challenge', {challenge_version}) && !challenge_version.is_locked;
        const label = isFirst() ? (<span className="hide-on-small-only">{`Preview`}</span>) : null;

        return (
            <div className="challenge-preview-next">
                <FeatureLock
                    enable={current_index < 3 || can_preview_full}
                    message={challenge_version.is_locked ? 'Please use the Challenges selected by your district administrator. This Challenge is not selected at this time' : 'continue previewing'}
                    upgrade_message={challenge_version.is_locked ? '' : undefined}
                    title={challenge_version.is_locked ? 'This Mindsets Challenge is locked' : undefined}
                    onClickCallback={() => {
                        if (challenge_version.is_locked) {
                            analytic.log('openChallengeLock', {
                                challenge_code: challenge_version.challenge_code.code,
                                challenge_name: challenge_version.name,
                                challenge_version: challenge_version.version,
                                element: "Next button"
                            });
                        }
                    }}
                    show_pricing_message={!challenge_version.is_locked}
                >
                    <button className="btn btn-large btn-challenge" onClick={goNext}>
                        {label}
                        <i className="fas fa-chevron-right"/>
                    </button>
                </FeatureLock>
            </div>
        );
    }

    function renderPreviewLayerReminder() {
        if (show_reminder) {
            return (
                <PreviewLayerReminder challenge_version={challenge_version}/>
            );
        }
    }

    function renderPreviewLayerFinished() {
        if (isLast()) {
            return (
                <PreviewLayerFinished
                    challenge_version={challenge_version}
                />
            );
        }
    }

    return (
        <div className="challenge-preview-container">
            <div className="challenge-preview-content-container">
                {children}
            </div>

            {renderPreviewLayerReminder()}
            {renderPreviewLayerFinished()}

            {renderButtonPrevious()}
            {renderButtonNext()}
        </div>
    );
}

PreviewFrame.propTypes = {
    goto: PropTypes.func.isRequired
}

export default PreviewFrame;
