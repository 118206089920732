import React, {Component} from 'react'
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types'

function selectInputText(element) {
    element.setSelectionRange(0, element.value.length);
}

class InlineEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editing: this.props.editing,
            text: this.props.text,
            minLength: this.props.minLength,
            maxLength: this.props.maxLength,
        }
    }

    componentDidMount() {
        this.isInputValid = this.props.validate || this.isInputValid;
        // Warn about deprecated elements
        if (this.props.element) {
            console.warn('`element` prop is deprecated: instead pass editingElement or staticElement to InlineEdit component');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const isTextChanged = (this.props.text !== prevProps.text);
        const isEditingChanged = (this.props.editing !== prevProps.editing);
        let nextState = {};
        if (isTextChanged) {
            nextState.text = this.props.text;
        }
        if (isEditingChanged) {
            nextState.editing = this.props.editing;
        }
        if (isTextChanged || isEditingChanged) {
            this.setState(nextState);
        }
        let inputElem = ReactDOM.findDOMNode(this.refs.input);
        if (this.state.editing && !prevState.editing) {
            inputElem.focus();
            selectInputText(inputElem);
        } else if (this.state.editing && prevProps.text != this.props.text) {
            this.finishEditing()();
        }
    }

    startEditing() {
        return e => {
            if (this.props.stopPropagation) {
                e.stopPropagation()
            }
            this.setState({editing: true, text: this.props.text});
        }
    };

    finishEditing() {
        return () => {
            if (this.isInputValid()(this.state.text) && this.props.text != this.state.text) {
                this.commitEditing();
            } else if (this.props.text === this.state.text || !this.isInputValid()(this.state.text)) {
                this.cancelEditing();
            }
        }
    };

    cancelEditing() {
        return this.setState({editing: false, text: this.props.text});
    };

    commitEditing() {
        this.setState({editing: false, text: this.state.text});
        let newProp = {};
        newProp[this.props.paramName] = this.state.text;
        this.props.change(newProp);
    };

    clickWhenEditing() {
        return e => {
            if (this.props.stopPropagation) {
                e.stopPropagation();
            }
        }
    };

    isInputValid() {
        return text => text.length >= this.state.minLength && text.length <= this.state.maxLength;
    };

    keyDown() {
        return event => {
            if (event.keyCode === 13) {
                this.finishEditing()();
            } else if (event.keyCode === 27) {
                this.cancelEditing();
            }
        }
    };

    textChanged() {
        return event => this.setState({text: event.target.value.trim()});
    };

    render() {
        if (this.props.isDisabled) {
            const Element = this.props.element || this.props.staticElement;
            return <Element
                className={this.props.className}
                style={this.props.style}>
                {this.state.text || this.props.placeholder}
            </Element>;
        } else if (!this.state.editing) {
            const Element = this.props.element || this.props.staticElement;
            return <Element
                className={this.props.className}
                onClick={this.startEditing()}
                tabIndex={this.props.tabIndex}
                style={this.props.style}>
                {this.state.text || this.props.placeholder}
            </Element>;
        } else {
            const Element = this.props.element || this.props.editingElement;
            return <Element
                onClick={this.clickWhenEditing()}
                onKeyDown={this.keyDown()}
                onBlur={this.finishEditing()}
                className={this.props.activeClassName}
                placeholder={this.props.placeholder}
                defaultValue={this.state.text}
                onChange={this.textChanged()}
                style={this.props.style}
                ref="input"/>;
        }
    }
}

InlineEdit.defaultProps = {
    minLength: 1,
    maxLength: 256,
    editingElement: 'input',
    staticElement: 'span',
    tabIndex: 0,
    isDisabled: false,
    editing: false
}

InlineEdit.propTypes = {
    text: PropTypes.string.isRequired,
    paramName: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    activeClassName: PropTypes.string,
    minLength: PropTypes.number,
    maxLength: PropTypes.number,
    validate: PropTypes.func,
    style: PropTypes.object,
    editingElement: PropTypes.string,
    staticElement: PropTypes.string,
    tabIndex: PropTypes.number,
    isDisabled: PropTypes.bool,
    editing: PropTypes.bool
}

export default InlineEdit;
