import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {get} from 'lodash';
import {ErrorMessageAction, LoadingAction, UpdateLessonAction} from "mindsets-js-sdk";

import analytic from 'utils/Analytic';
import InlineEdit from 'common/InlineEdit';


export default function () {
    const dispatch = useDispatch();
    const lesson = useSelector(state => state.lesson);
    let lesson_name_elem;

    function editLesson(data) {
        dispatch(UpdateLessonAction(lesson.id, data)).catch(error => {
            dispatch(ErrorMessageAction(error));
            console.error(error)
        }).then(() => dispatch(LoadingAction(false)));
    }

    function renderLessonName() {
        const onClick = () => {
            lesson_name_elem.startEditing()();
            analytic.log('classSetupClassNameEdit', lesson.code);
        }
        return (
            <div className="class-details-name flex-row flex-v-center">
                <InlineEdit
                    activeClassName="inline-editing"
                    text={lesson.name}
                    paramName="name"
                    change={editLesson}
                    minLength={1}
                    maxLength={50}
                    ref={(elem) => {
                        lesson_name_elem = elem
                    }}
                />

                <i className="fas fa-pen" onClick={onClick}></i>
            </div>
        );
    }

    return (
        <div className="setup-class-details">
            <h4 className="header-new">
                {`Class details`}
            </h4>

            <div className="section">
                <div className="flex-box-5-10">
                    <div>
                        <div className="flex-row flex-v-center">
                            <div className="class-details-label">
                                {`Mindsets Code`}
                            </div>
                            <div className="class-details-code">
                                {lesson.code}
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="flex-row flex-v-center">
                            <div className="class-details-label">
                                {`Challenge`}
                            </div>
                            <div>
                                {get(lesson, 'challenge_version.name')}
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="flex-row flex-v-center">
                            <div className="class-details-label">
                                {`Class name`}
                            </div>
                            <div className="flex-auto">
                                {renderLessonName()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
