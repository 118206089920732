import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {find, isEmpty} from 'lodash'

import {
    formatAnswer, formatSpreadsheet, formatVote
} from './utils'


class AnswerOld extends Component {
    renderAnswerRow(key, formatted_answer) {
        return (
            <div key={key} className="stream-answer-row">
                {formatted_answer}
            </div>
        )
    }

    renderAnswers() {
        const render_content = []
        const {answers, votes, user_spreadsheets} = this.props.selected_stream_entity
        const {polls} = this.props.selected_challenge_stream_entity

        if (!isEmpty(polls)) {
            const vote = find(votes, (v) => v.poll_id === polls[0].id)
            if (vote) {
                render_content.push(
                    this.renderAnswerRow(`vote_${vote.id}`, formatVote(vote))
                )
            }
        }

        if (!isEmpty(user_spreadsheets)) {
            user_spreadsheets.map(user_spreadsheet => {
                render_content.push(
                    this.renderAnswerRow(`spreadsheet_${user_spreadsheet.id}`, formatSpreadsheet(user_spreadsheet))
                )
            });
        }

        if (!isEmpty(answers)) {
            answers.map((answer) => {
                render_content.push(
                    this.renderAnswerRow(`answer_${answer.id}`, formatAnswer(answer))
                )
            })
        }

        return render_content
    }

    render() {
        return (
            <div className="byquestion-stream-answer">
                {this.renderAnswers()}
            </div>
        )
    }
}

AnswerOld.propTypes = {
    selected_stream_entity: PropTypes.object.isRequired,
    selected_challenge_stream_entity: PropTypes.object.isRequired,
}

export default AnswerOld
