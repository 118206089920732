import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'

import {graphingAnswerConfig} from 'utils/ggb'


class Graphing extends Component {
    constructor(props) {
        super(props)
        this.uniq_id = 'answer-graphing-' + Date.now() + '-' + Math.floor(Math.random() * 100)
    }

    componentDidMount() {
        this.ggbInit()
    }

    ggbInit() {
        const ggb_config = {
            ...graphingAnswerConfig(this.uniq_id),
            appletOnLoad: (graphing_instance) => {
                this.graphing_instance = graphing_instance
            }
        }

        const base64 = get(this.props.response, 'data.answer.base64')
        if (base64) {
            ggb_config.ggbBase64 = base64
        }

        this.ggbApp = new GGBApplet(ggb_config, true)
        this.ggbApp.inject(this.uniq_id)
    }

    render() {
        return (
            <div id={`component-${this.uniq_id}`}>
                <div id={this.uniq_id}></div>
            </div>
        )
    }
}

Graphing.propTypes = {
    response: PropTypes.object
}

export default Graphing
