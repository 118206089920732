import React from 'react';
import {useSelector} from 'react-redux';
import {get} from 'lodash';

import STable from './STable';

export default function ({selectPage, printable}) {

    // Only show data graph in Mindsets University Dashboard for now
    const dashboard = useSelector(state => state.insights_dashboard);
    const school_name = get(dashboard, 'project.school.name', '');
    const district_name = get(dashboard, 'project.district.name', '');
    if (school_name.toUpperCase() != "MINDSETS UNIVERSITY" && district_name.toUpperCase() != "MINDSETS UNIVERSITY") {
        return null;
    }

    const dashboard_data = useSelector(state => state.dashboard_data);
    const data_table = dashboard_data.data_domain_table;
    if (!data_table || !data_table.total) {
        return null;
    }

    return (
        <div>
            <STable
                selectPage={selectPage}
                printable={printable}
            />
        </div>
    );
}
