import React, {Component} from 'react'


class Content1 extends Component {
    render() {
        return (
            <div>
                <h3 className="header-new">
                    {`Module 1: What are the key components of a Mindsets Challenge?`}
                </h3>

                <br />

                <div>
                    <strong>
                        {`The key components of a Mindsets Challenge include the Library, Preview, Facilitation View and Open Reader.`}
                    </strong>
                </div>
                <br />
                <div>
                    <strong>
                        {`Each Challenge includes several questions, a poll, and a bonus section and is estimated to take between 45-60 minutes.`}
                    </strong>
                </div>

                <br />

                <h4 className="header-new">
                    {`Library`}
                </h4>
                <div>
                    {`Shows all Challenges can be filtered by grade, math/science topic, or student interest (Theme).`}
                </div>
                <div>
                    <br />
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/filters.png`}
                        alt="A screenshot showing the options in the topic, grade, and theme filter dropdowns on the top of the Challenge library web page."
                        title="A screenshot showing the options in the topic, grade, and theme filter dropdowns on the top of the Challenge library web page."
                    />
                </div>

                <br />

                <h4 className="header-new">
                    {`Preview`}
                </h4>
                <div>
                    {`Click on Preview to scan through the lesson.`}
                </div>
                <div>
                    <br />
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/library_preview.png`}
                        alt="A screenshot showing the preview button when hovering over a Challenge card in the Challenge library web page."
                        title="A screenshot showing the preview button when hovering over a Challenge card in the Challenge library web page."
                    />
                </div>
                <br />
                <div>
                    {`Click `}
                    <strong>{`Teacher Guide`}</strong>
                    {` to download and print the entire lesson.`}
                </div>
                <div>
                    <br />
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/teacher_guide.png`}
                        alt="A screenshot showing the teacher guide button in the bottom right corner of the Challenge preview web page."
                        title="A screenshot showing the teacher guide button in the bottom right corner of the Challenge preview web page."
                    />
                </div>

                <br />

                <h4 className="header-new">
                    {`Open Reader`}
                </h4>
                <div>
                    {`Open Reader uses Microsoft's Immersive Reader technology and allows students to access the material in various ways.`}
                </div>
                <ul>
                    <li>{`Translation by word or by doc`}</li>
                    <li>{`Read-aloud voice`}</li>
                    <li>{`Picture dictionary`}</li>
                    <li>{`Focus on 1-3 lines of text at a time`}</li>
                    <li>{`Parts of speech (noun, adjective, verb)`}</li>
                    <li>{`Change text size`}</li>
                </ul>
                <div>
                    {`Easily access Open Reader for inclusive, integrated learning and collaboration in math across languages.`}
                </div>
                <div>
                    <br />
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/openreader_button.png`}
                        alt="A screenshot showing the Open Reader button in the upper right corner of the Challenge web page."
                        title="A screenshot showing the Open Reader button in the upper right corner of the Challenge web page."
                    />
                </div>
                <br />
                <div>
                    {`Translate text and use the picture dictionary. Accessible in over 70 languages. `}
                    {`The example below shows a student selecting Spanish translation and highlighting the word "company" in Spanish.`}
                </div>
                <div>
                    <br />
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/openreader.png`}
                        alt="A screenshot showing Open Reader with the text content translated to Spanish and the settings panel for configuring preferences on the right-hand side."
                        title="A screenshot showing Open Reader with the text content translated to Spanish and the settings panel for configuring preferences on the right-hand side."
                    />
                </div>
                <div>
                    {`Learn all about Microsoft's Immersive Reader and its extensive evidence based research at `}
                    <a href="https://education.microsoft.com/en-us/resource/9b010288" target="_blank">
                        {`https://education.microsoft.com/en-us/resource/9b010288`}
                    </a>
                    {`.`}
                </div>

                <br />

                <h4 className="header-new">
                    {`Facilitation View`}
                </h4>
                <div>
                    {`Shows where each student is in the Challenge and how they have responded.`}
                </div>
                <div>
                    <br />
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/class_progress.png`}
                        alt="A screenshot of the Facilitation View web page with the progress bars of 5 students."
                        title="A screenshot of the Facilitation View web page with the progress bars of 5 students."
                    />
                </div>

                <div className="section">
                    <div className="divider"></div>
                </div>

                <h4 className="header-new">
                    {`Reflection`}
                </h4>
                <div>
                    <strong>
                        {`Take a moment to reflect on what elements of Mindsets you are already using in the classroom. `}
                    </strong>
                    <strong>
                        {`Consider how you prepare for a new lesson, how English learners are supported, and how student progress is monitored in real time.`}
                    </strong>
                </div>
                <br />
                <div>
                    <strong>
                        {`What elements would you like to improve on most?`}
                    </strong>
                </div>
            </div>
        )
    }
}

export default Content1
