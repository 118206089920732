import React, {Component} from 'react'

import './bouncing.scss';

class Bouncing extends Component {
    render() {
        return (
            <div className="common-ui-bouncing">
                <div className="bouncing-box">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default Bouncing
