import React, {Component} from 'react'
import PropTypes from 'prop-types'

class Teacher extends Component {
    renderStatus() {
        if (this.props.joined) {
            return 'Joined';
        } else {
            return '';
        }
    }

    renderAvatar() {
        if (this.props.teacher.avatar) {
            return (
                <div className={'cashtivity-icon ' + this.props.teacher.avatar}/>
            );
        }

        return (
            <i className={"fas fa-user-circle mr-2"}/>
        );
    }

    render() {
        return (
            <div
                className={'flex-row flex-v-center my-2 teacher-item ' + (this.props.joined ? 'joined' : '')}
                onClick={() => this.props.joined ? null : this.props.addUser(this.props.teacher.id)}
            >
                {this.renderAvatar()}
                <div className='flex-grow-1'>
                    <div>{this.props.teacher.fname} {this.props.teacher.lname}</div>
                    <div className="flex-row flex-between">
                        <div>{this.props.teacher.email}</div>
                        <div>{this.renderStatus()}</div>
                    </div>
                </div>
            </div>
        );
    }
}

Teacher.propTypes = {
    teacher: PropTypes.shape({
        id: PropTypes.number.isRequired,
        email: PropTypes.string.isRequired,
        fname: PropTypes.string.isRequired,
        lname: PropTypes.string,
        avatar: PropTypes.string
    }).isRequired,
    joined: PropTypes.bool.isRequired,
    addUser: PropTypes.func.isRequired
};

export default Teacher;
