import React, {Component} from 'react'


class Content3 extends Component {
    render() {
        return (
            <div>
                <h3 className="header-new">
                    {`Module 3: How to prepare for a Mindsets Challenge`}
                </h3>
                <br />
                <div>
                    <strong>
                        {`Once you've decided which Challenge you will assign, download the teacher guide and create a class code.`}
                    </strong>
                </div>

                <br />

                <h4 className="header-new">
                    {`Download the teacher guide`}
                </h4>
                <div>
                    {`The teacher guide includes everything the students will see and do as well as facilitator tips. `}
                    {`All guides include the estimated time the Challenge will take, a warm-up activity, answer key and rubric. `}
                    {`It is good practice to have the teacher guide printed and readily available while facilitating a Challenge.`}
                </div>
                <div>
                    <br />
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/teacher_guide.png`}
                        alt="A screenshot showing the teacher guide button in the lower right corner of the Challenge preview webpage."
                        title="A screenshot showing the teacher guide button in the lower right corner of the Challenge preview webpage."
                    />
                </div>

                <br />

                <h4 className="header-new">
                    {`Create a class code`}
                </h4>
                <div>
                    {`A new class code should be created for each class and as close to the start date as possible.`}
                </div>
                <div>
                    <br />
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/create_class.png`}
                        alt="A screenshot showing the class code create button in the upper right corner of the Challenge webpage."
                        title="A screenshot showing the class code create button in the upper right corner of the Challenge webpage."
                    />
                </div>
                <br />
                <div>
                    {`You can share or assign the Mindsets Class Code with your students in a number of ways including a share link, with Google Classroom or with Microsoft Teams.`}
                </div>
                <div>
                    <br />
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/class_setup.png`}
                        alt="A screenshot showing the class setup page to adjust the class settings and instructions for sharing the class via code, link, Google Classroom and Microsoft teams."
                        title="A screenshot showing the class setup page to adjust the class settings and instructions for sharing the class via code, link, Google Classroom and Microsoft teams."
                    />
                </div>

                <br />

                <h4 className="header-new">
                    {`Decide when and how students will complete the Challenge`}
                </h4>
                <div>
                    {`Challenges can be completed over one or two class blocks. However, the more discussion you encourage your students to have in class, the more time you'll need to allow. `}
                    {`We recommend that students work in pairs to encourage collaboration and communication.`}
                </div>

                <br />

                <h4 className="header-new">
                    {`Share class code with students`}
                </h4>
                <div>
                    {`Students are able to access a Challenge once they are given the class code. `}
                    {`They can work on Mindsets Challenges using a mobile device, laptop, Chromebook, or iPad. `}
                    {`Remind students if they need additional materials such as paper, pencil, or a calculator.`}
                </div>
                <div>
                    <br />
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/class_code.png`}
                        alt="A screenshot showing how to share a class code in the Facilitation View webpage."
                        title="A screenshot showing how to share a class code in the Facilitation View webpage."
                    />
                </div>

                <br />

                <h4 className="header-new">
                    {`Gold Standard practices`}
                </h4>
                <div>
                    {`These Gold Standard practices can assist you in assigning the Challenge. `}
                    {`Don't worry, as with all new lessons or tools, you will improve over time. `}
                    {`Students will also become familiar with the structure and expectations of Challenges.`}
                </div>
                <div>
                    <br />
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/challenge-content/tpd1/gold_practice_1.png`}
                        alt="A graphic showing 3 tips for starting a Mindsets Challenge."
                        title="A graphic showing 3 tips for starting a Mindsets Challenge."
                    />
                </div>

                <div className="section">
                    <div className="divider"></div>
                </div>

                <h4 className="header-new">
                    {`Reflection:`}
                </h4>
                <div>
                    <strong>
                        {`Select a standard, topic, or theme, then a corresponding Challenge. `}
                    </strong>
                    <strong>
                        {`Think about how you will prepare for the Challenge.`}
                    </strong>
                </div>
                <ul>
                    <li><strong>{`How will students be paired?`}</strong></li>
                    <li><strong>{`How many class periods or how much time will you allot for the Challenge?`}</strong></li>
                    <li><strong>{`Will you use the rubric?`}</strong></li>
                </ul>
            </div>
        )
    }
}

export default Content3
