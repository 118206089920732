import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {COUNTRIES} from 'constants/countries'

class CurriculumSelector extends Component {
    extractCurriculumGroups() {
        let groups = {};
        this.props.curriculums.map(curriculum => {
            if (groups.hasOwnProperty(curriculum.country_code) && !groups[curriculum.country_code].includes(curriculum.code)) {
                groups[curriculum.country_code].push(curriculum);
            } else if (!groups.hasOwnProperty(curriculum.country_code)) {
                groups[curriculum.country_code] = [curriculum];
            }
        });

        return groups;
    }

    renderOptionsByuGroups() {
        const groups = this.extractCurriculumGroups();
        const groupOptions = [];
        groupOptions.push(<option key={'empty'} value='' disabled>Select curriculum</option>);
        groupOptions.push(<option key='separator' disabled>──────────</option>);

        Object.keys(groups).map(country_code => {
            let curriculumOptions = [];
            groups[country_code]
                .filter(curriculum => curriculum.country_code === country_code)
                .map(curriculum => curriculumOptions.push(
                    <option
                        key={curriculum.id}
                        value={curriculum.id}
                    >{curriculum.name}</option>
                ));
            groupOptions.push(
                <optgroup
                    key={country_code}
                    label={COUNTRIES[country_code]}
                >{curriculumOptions}</optgroup>
            );
        });

        groupOptions.push(<option key={'none'} value=''>Other</option>);

        return groupOptions;
    }

    render() {
        return (
            <div className="inputfield">
                <label htmlFor="curriculum" className="flex-row">
                    <div>Curriculum</div>
                    {
                        this.props.validation_error_messages.hasOwnProperty('curriculum') ?
                            <i className="fas fa-exclamation-circle text-red ml-2"></i> : null
                    }
                </label>
                <select
                    value={this.props.curriculum_id ? this.props.curriculum_id : ''}
                    onChange={event => this.props.onCurriculumIdChange(parseInt(event.target.value))}
                    id="curriculum"
                    className={"standard-switcher browser-default "}>
                    {this.renderOptionsByuGroups()}
                </select>
            </div>
        );
    }
}

CurriculumSelector.propTypes = {
    curriculum_id: PropTypes.number,
    onCurriculumIdChange: PropTypes.func.isRequired,
    validation_error_messages: PropTypes.shape({
        curriculum: PropTypes.string
    }).isRequired,
    curriculums: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        code: PropTypes.string.isRequired,
        country_code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        is_enabled: PropTypes.bool.isRequired,
        is_code_shown: PropTypes.bool.isRequired,
        grade_term: PropTypes.string.isRequired
    })).isRequired
};

export default CurriculumSelector;
