import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import MindsetsJsSDK, {toastActions} from "mindsets-js-sdk";


class Content6 extends Component {
    onClick() {
        return () => {
            if (this.props.teacher_pd_code) {
                MindsetsJsSDK().Api.lesson
                    .joinPD(this.props.teacher_pd_code)
                    .then(response => this.props.navigate('/hello/' + response.stream_code))
                    .catch(error => this.props.dispatch(toastActions.ErrorMessageAction(error)));
            } else {
                console.error('Teacher PD code is empty.')
            }
        }
    }

    render() {
        return (
            <div>
                <div className="center-align">
                    <img className="teacher-pd-badge"
                         src={`${process.env.PUBLIC_S3_ASSETS_URL}/badges/tpd1.png`} alt='Badge'/>
                </div>

                <div className="flex-row flex-h-center">
                    <button className="btn btn-challenge" onClick={this.onClick()}>
                        {`Start Quiz`}
                    </button>
                </div>
            </div>
        )
    }
}

Content6.propTypes = {
    teacher_pd_code: PropTypes.string,
    navigate: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired
}

export default props => (
    <Content6
        {...props}
        navigate={useNavigate()}
        dispatch={useDispatch()}
    />
);
