import React, {useEffect} from 'react';
import {Link, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {CustomDropdown, LoadDistrictsAction, filterConstants} from "mindsets-js-sdk";

import useAuth from "../../hooks/useAuth";
import {
    LoadInsightsDashboardsAction,
    LoadedInsightsDashboardAchievementLevelFilterAction,
    LoadedInsightsDashboardChallengeFilterAction, LoadedInsightsDashboardFilterDependenciesAction,
    LoadedInsightsDashboardGradeFilterAction,
    LoadedInsightsDashboardImpactStudyFilterAction,
    LoadedInsightsDashboardPracticeFilterAction,
    LoadedInsightsDashboardPracticeSkillFilterAction,
    LoadedInsightsDashboardSchoolFilterAction, LoadedInsightsDashboardStudentFilterAction,
    LoadedInsightsDashboardSubjectFilterAction,
    LoadedInsightsDashboardTeacherFilterAction,
    SetDashboardFiltersAction,
    CleanDashboardDataAction
} from "../../actions/performance_actions";
import {default_filters} from "../../reducers/performance_reducers";

export default function () {
    useAuth(['teacher']);

    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const district_id = !isNaN(parseInt(searchParams.get('district_id'))) ? parseInt(searchParams.get('district_id')) : null;
    const me = useSelector(state => state.me);
    const dashboards = useSelector(state => state.dashboards);
    const districts = useSelector(state => state.districts);
    const projects = {};
    dashboards.map(dashboard => {
        if (!Object.keys(projects).includes(dashboard.project_id)) {
            projects[dashboard.project_id] = dashboard.project;
        }
    });
    const dashboardsGrouped = Object.groupBy(dashboards, ({project_id}) => project_id);

    function action() {
        const options = {
            'selectors[0]': 'project'
        };
        if (me && me.is_dashboard_super_admin && district_id) {
            options['filters[district_id][0]'] = filterConstants.EXPRESSION_EQUAL + '=' + district_id;
        }
        dispatch(LoadInsightsDashboardsAction(options));
    }

    useEffect(() => {
        if (me && me.is_dashboard_super_admin) {
            dispatch(LoadDistrictsAction({'filters[has_projects][0]': filterConstants.EXPRESSION_TRUE}));
        }
        if (me) {
            action();
        }
    }, [me]);
    useEffect(() => {
        action();
    }, [district_id]);
    useEffect(() => {
        return () => {
            dispatch(SetDashboardFiltersAction(default_filters));
            dispatch(LoadedInsightsDashboardImpactStudyFilterAction([]));
            dispatch(LoadedInsightsDashboardPracticeFilterAction([]));
            dispatch(LoadedInsightsDashboardSubjectFilterAction([]));
            dispatch(LoadedInsightsDashboardGradeFilterAction([]));
            dispatch(LoadedInsightsDashboardSchoolFilterAction([]));
            dispatch(LoadedInsightsDashboardChallengeFilterAction([]));
            dispatch(LoadedInsightsDashboardTeacherFilterAction([]));
            dispatch(LoadedInsightsDashboardPracticeSkillFilterAction([]));
            dispatch(LoadedInsightsDashboardStudentFilterAction([]));
            dispatch(LoadedInsightsDashboardAchievementLevelFilterAction([]));
            dispatch(LoadedInsightsDashboardFilterDependenciesAction([]));
            dispatch(CleanDashboardDataAction());
        }
    }, []);

    function renderDashboardGroups() {
        return Object.keys(projects).sort((a, b) => {
            if (a < b) {
                return 1;
            } else if (a > b) {
                return -1;
            }

            return 0;
        }).map(project_id => {
            return (
                <div key={project_id} className='section'>
                    <h3 className='header-new'>{projects[project_id].name}</h3>
                    <hr/>
                    <div
                        className='flex-row flex-gap-20 flex-wrap'>{dashboardsGrouped[project_id].map(renderDashboard)}</div>
                </div>
            );
        });
    }

    function renderDashboard(dashboard) {
        return (
            <Link
                to={`/insights/${dashboard.id}` + (me && me.is_dashboard_super_admin && district_id ? '?district_id=' + district_id : '')}
                key={dashboard.id}
                className='bordered-10 hoverable'
                style={{maxWidth: '315px'}}
            >
                <div className='p-2'>
                    <img
                        alt={dashboard.title}
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/dashboards/${dashboard.type}.png`}/>
                    <hr/>
                    <h4 className='header-new'>{dashboard.title}</h4>
                    <div>{dashboard.description}</div>
                </div>
            </Link>
        );
    }

    function renderSwitchOptions() {
        const options = {
            '0': <a onClick={event => {
                event.preventDefault();
                searchParams.delete('district_id');
                setSearchParams(searchParams);
            }}>PLEASE SELECT</a>
        };
        districts.map(district => {
            options[district.id.toString()] = <a onClick={event => {
                event.preventDefault();
                if (district.id === me.school.district_id) {
                    searchParams.delete('district_id');
                } else {
                    searchParams.set('district_id', district.id);
                }
                setSearchParams(searchParams);
            }}>{district.name.toUpperCase()}</a>;
        });

        return options;
    }

    function renderDistrictSwitch() {
        if (me.is_dashboard_super_admin) {
            return (
                <CustomDropdown
                    trigger={<i className='fas fa-cog'/>}
                    no_wrap={true}
                    items={renderSwitchOptions()}
                    disabled={districts.length <= 0}
                    selected={district_id ? district_id.toString() : ''}
                    use_arrow={false}
                />
            );
        }
        return null;
    }

    if (!me) {
        return null;
    }

    return (
        <div id='insights-dashboards'>
            <div className="container">
                <div className='flex-row flex-between flex-v-center'>
                    <h2 className='header-new'>Insights Dashboards</h2>
                    {renderDistrictSwitch()}
                </div>
                {renderDashboardGroups()}
            </div>
        </div>
    );
}
