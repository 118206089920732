import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import moment from "moment";
import Cookie from "mindsets-js-sdk/src/Cookie";

import ChallengeTile from './ChallengeTile';
import LessonRow from './LessonRow';
import LessonFilter, {FILTER_COOKIE_NAME} from './LessonFilter';
import ViewModeSwitcher from "./ViewModeSwitcher";

class LessonList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            order: Cookie.getCookie(FILTER_COOKIE_NAME + props.list_name + '_order') ? Cookie.getCookie(FILTER_COOKIE_NAME + props.list_name + '_order') : 'created_at:desc',
            show_hidden: true,
            from: Cookie.getCookie(FILTER_COOKIE_NAME + props.list_name + '_from') && !isNaN(parseInt(Cookie.getCookie(FILTER_COOKIE_NAME + props.list_name + '_from'))) ? parseInt(Cookie.getCookie(FILTER_COOKIE_NAME + props.list_name + '_from')) : null,
            till: Cookie.getCookie(FILTER_COOKIE_NAME + props.list_name + '_till') && !isNaN(parseInt(Cookie.getCookie(FILTER_COOKIE_NAME + props.list_name + '_till'))) ? parseInt(Cookie.getCookie(FILTER_COOKIE_NAME + props.list_name + '_till')) : null,
            limit: Cookie.getCookie(FILTER_COOKIE_NAME + props.list_name + '_limit') ? parseInt(Cookie.getCookie(FILTER_COOKIE_NAME + props.list_name + '_limit')) : null,
            mode: Cookie.getCookie('mindsets_' + props.list_name + '_view_mode') ? Cookie.getCookie('mindsets_' + props.list_name + '_view_mode') : 'grid',
            hide_done: false
        }
    }

    onOrderChange() {
        return order => this.setState({
            order,
            limit: null
        }, Cookie.setCookie(FILTER_COOKIE_NAME + this.props.list_name + '_order', order));
    }

    updateFilterState() {
        return (key, value) => {
            Cookie.setCookie(FILTER_COOKIE_NAME + this.props.list_name + '_' + key, value);
            if (key === 'limit') {
                this.setState({[key]: value});
            } else {
                Cookie.setCookie(FILTER_COOKIE_NAME + this.props.list_name + '_limit', null);
                this.setState({[key]: value, limit: null});
            }
        }
    }

    onViewModeChange() {
        return mode => this.setState({mode}, Cookie.setCookie('mindsets_' + this.props.list_name + '_view_mode', mode));
    }

    filterLessons(lessons) {
        if (this.state.from) {
            lessons = lessons.filter(lesson => lesson.created_at > this.state.from);
        }
        if (this.state.till) {
            lessons = lessons.filter(lesson => lesson.created_at < this.state.till);
        }
        if (this.state.hide_done) {
            lessons = lessons.filter(lesson => !lesson.is_done);
        }
        if (this.state.limit) {
            lessons = lessons.slice(0, this.state.limit);
        }
        // lessons = lessons.filter(lesson => {
        //     if (false === this.state.show_hidden) {
        //         return !lesson.is_hidden || lesson.is_hidden === false;
        //     }
        //
        //     return true;
        // })

        return lessons;
    }

    orderLessons(lessons) {
        return lessons.sort((a, b) => {
            const orders = this.state.order.split(':');
            if (orders.length === 2) {
                if (orders[1] === 'asc') {
                    if (orders[0] === 'challenge') {
                        return a.challenge_version.name > b.challenge_version.name ? 1 : -1;
                    } else {
                        return a[orders[0]] > b[orders[0]] ? 1 : -1;
                    }
                } else {
                    if (orders[0] === 'challenge') {
                        return a.challenge_version.name < b.challenge_version.name ? 1 : -1;
                    } else {
                        return a[orders[0]] < b[orders[0]] ? 1 : -1;
                    }
                }
            }
        });
    }

    groupLessons(lessonsSource) {
        let groups = [
            {key: 'today', name: 'Today', items: []},
            {key: 'this_week', name: 'Earlier this week', items: []},
            {key: 'last_week', name: 'Last week', items: []},
            {key: 'this_month', name: 'Earlier this month', items: []},
            {key: 'last_month', name: 'Last month', items: []},
            {key: 'this_year', name: 'Earlier this year', items: []},
        ];
        lessonsSource.map(lesson => {
            const created_at = moment(lesson.created_at * 1000);
            if (created_at.isBetween(moment().startOf('day'), moment().endOf('day'))) {
                groups.find(group => group.key === 'today').items.push(lesson);
            } else if (created_at.isBetween(moment().startOf('week'), moment().startOf('day'))) {
                groups.find(group => group.key === 'this_week').items.push(lesson);
            } else if (created_at.isBetween(moment().subtract(1, 'w').startOf('week'), moment().endOf('week'))) {
                groups.find(group => group.key === 'last_week').items.push(lesson);
            } else if (created_at.isBetween(moment().startOf('month'), moment().subtract(2, 'w').endOf('week'))) {
                groups.find(group => group.key === 'this_month').items.push(lesson);
            } else if (created_at.isBetween(moment().subtract(1, 'm').startOf('month'), moment().subtract(2, 'w').endOf('week'))) {
                groups.find(group => group.key === 'last_month').items.push(lesson);
            } else if (created_at.isBetween(moment().startOf('year'), moment().subtract(2, 'm').endOf('month'))) {
                groups.find(group => group.key === 'this_year').items.push(lesson);
            } else {
                const year = created_at.year();
                if (!groups.find(group => group.key === year)) {
                    groups.push({key: year, name: year, items: []});
                }
                groups.find(group => group.key === year).items.push(lesson);
            }
        });
        const orders = this.state.order.split(':');
        if (orders.length === 2 && orders[0] === 'created_at' && orders[1] === 'asc') {
            groups.reverse();
        }

        return groups;
    }

    renderLessons(lessons) {
        if (this.state.mode === 'list') {
            return (
                <div className='flex flex-column'>
                    {lessons.map(lesson => {
                        return (
                            <Link
                                to={`/teacher/class/${lesson.code}`}
                                key={lesson.code}
                                className='lesson-link'
                            >
                                <LessonRow
                                    key={lesson.id}
                                    lesson={lesson}
                                    challenge_version={lesson.challenge_version}
                                    show_challenge_name={true}
                                />
                            </Link>
                        );

                    })}
                </div>
            );
        } else {
            return (
                <div className='flex-row-m flex-wrap flex-box-15-15'>
                    {lessons.map(lesson => {
                        return (
                            <Link
                                to={`/teacher/class/${lesson.code}`}
                                key={lesson.code}
                                className='class-link'
                            >
                                <ChallengeTile
                                    challenge_version={lesson.challenge_version}
                                    description={lesson.name.length > 30 ? (lesson.name.substring(0, 30) + '...') : lesson.name}
                                    is_done={lesson.is_done}
                                />
                            </Link>
                        );
                    })}
                </div>
            );
        }
    }

    renderLessonGroups() {
        if (this.props.lessons.length) {
            let lessons = this.filterLessons(this.props.lessons);
            const groups = this.groupLessons(lessons).filter(group => group.items.length > 0);
            if (groups.length) {
                return (
                    <div>
                        {groups.map(group => {
                            return (
                                <div key={group.key}>
                                    <div>&nbsp;</div>
                                    <div>&nbsp;</div>
                                    <h5>{group.name}</h5>
                                    {this.renderLessons(this.orderLessons(group.items))}
                                </div>
                            );
                        })}
                    </div>
                );

            } else {
                return this.renderEmptyState();
            }
        }

        return this.renderEmptyState();
    }

    renderEmptyState() {
        return (
            <div className="coteaching-empty-state">
                <div className="center-align">
                    <img
                        src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/teacher_dashboard_coteaching_empty.png`}
                        alt='No Classes found yet'/>
                </div>
                <div className="coteaching-empty-state-hint center-align">
                    {`Classes that you will create will appear here`}
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="teacher-dashboard-coteaching">
                <div className="section">
                    <div className='flex-row flex-wrap flex-between flex-v-center flex-gap-10'>
                        <LessonFilter
                            order={this.state.order}
                            show_hidden={this.state.show_hidden}
                            limit={this.state.limit}
                            onOrderChange={this.onOrderChange()}
                            updateState={this.updateFilterState()}
                            hide_done={this.state.hide_done}
                            list_name={this.props.list_name}
                            from={this.state.from}
                            till={this.state.till}
                        />
                        <ViewModeSwitcher
                            mode={this.state.mode}
                            onViewModeChange={this.onViewModeChange()}
                        />
                    </div>
                    {this.renderLessonGroups()}
                </div>
            </div>
        );
    }
}

LessonList.defaultProps = {
    list_name: 'my_classes'
}

LessonList.propTypes = {
    lessons: PropTypes.arrayOf(PropTypes.shape({
        code: PropTypes.string.isRequired,
        is_hidden: PropTypes.bool,
        is_done: PropTypes.bool,
        created_at: PropTypes.number.isRequired,
        challenge_version: PropTypes.shape({
            name: PropTypes.string.isRequired,
            challenge_code: PropTypes.shape({
                color: PropTypes.string.isRequired
            }).isRequired
        }).isRequired
    })).isRequired,
    list_name: PropTypes.string
}

export default LessonList;
