import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './sticky.scss';


class Sticky extends Component {
    componentDidMount() {
        window.onscroll = () => {
            this.onScroll()
        }
    }

    onScroll() {
        if (window.pageYOffset > this.refs.div.offsetTop) {
            this.refs.div.classList.add("sticky-div")
            this.refs.div.classList.remove("not-sticky-div")
        } else {
            this.refs.div.classList.remove("sticky-div")
            this.refs.div.classList.add("not-sticky-div")
        }
    }

    render() {
        return (
            <div className="common-ui-sticky not-sticky-div" ref="div" style={this.props.style}>
                {this.props.children}
            </div>
        )
    }
}

Sticky.defaultProps = {
    style: {
        top: '0',
        left: '0',
        right: '0'
    }
}

Sticky.propTypes = {
    style: PropTypes.object
}

export default Sticky
