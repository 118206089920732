import React, {Component} from 'react'
import PropTypes from 'prop-types'

import CardSelector from 'common/challenge/CardSelector'


class CardNavigator extends Component {
    renderPrevious() {
        const {selected_card_index} = this.props
        const onClick = () => {
            this.props.onSelect(selected_card_index - 1)
        }
        const extra_class = selected_card_index <= 0 ? ' visibility-hidden' : ''
        return (
            <button className={"btn btn-grey hide-on-small-only " + extra_class} onClick={onClick}>
                <i className="fas fa-angle-left"></i>
            </button>
        )
    }

    renderCurrent() {
        return (
            <CardSelector
                challenge_version={this.props.challenge_version}
                stream_entities={this.props.stream_entities}
                selected_card_index={this.props.selected_card_index}
                onSelect={this.props.onSelect}
                popup_setting={this.props.popup_setting}
            />
        )
    }

    renderNext() {
        const {selected_card_index} = this.props
        const {final_index} = this.props.challenge_version
        const onClick = () => {
            this.props.onSelect(selected_card_index + 1)
        }
        const extra_class = selected_card_index >= final_index ? ' visibility-hidden' : ''
        return (
            <button className={"btn btn-grey hide-on-small-only " + extra_class} onClick={onClick}>
                <i className="fas fa-angle-right"></i>
            </button>
        )
    }

    render() {
        return (
            <div className="challenge-card-navigator">
                <div className="flex-row flex-box-5-10">
                    <div>
                        {this.renderPrevious()}
                    </div>

                    <div>
                        {this.renderCurrent()}
                    </div>

                    <div>
                        {this.renderNext()}
                    </div>
                </div>
            </div>
        )
    }
}

CardNavigator.defaultProps = {
    popup_setting: {right: '-160px'}
}

CardNavigator.propTypes = {
    challenge_version: PropTypes.shape({
        final_index: PropTypes.number.isRequired
    }).isRequired,
    stream_entities: PropTypes.array.isRequired,
    selected_card_index: PropTypes.number.isRequired,
    onSelect: PropTypes.func.isRequired,
    popup_setting: PropTypes.object
}

export default CardNavigator
