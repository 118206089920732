import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";

import CreateClassCode from 'common/challenge/CreateClassCode'


class Header extends Component {
    renderBackButton() {
        if (this.props.show_back_button) {
            const onClick = (e) => {
                e.preventDefault();
                this.props.navigate(-1);
            }

            return (
                <i className="fas fa-chevron-left" onClick={onClick}/>
            )
        }
    }

    renderGoToPreview() {
        if (this.props.show_preview_button) {
            return (
                <Link className="btn btn-flat" to={`/explore/${this.props.challenge_version.challenge_code.code}`}>
                    Preview
                </Link>
            )
        }
    }

    renderCreateButton() {
        if (this.props.show_create_button) {
            return (
                <CreateClassCode
                    challenge_version={this.props.challenge_version}
                    beforeCreate={this.props.beforeCreate}
                    afterCreate={this.props.afterCreate}
                />
            )
        }
    }

    renderMore() {
        if (this.props.more_to_render) {
            return this.props.more_to_render
        }
    }

    render() {
        if (!this.props.me) {
            return null;
        }

        return (
            <div className={`challenge-header challenge-header-${this.props.me.persona} z-depth-1`}>
                <div className="challenge-header-container container flex-column flex-h-center">
                    <div className="flex-row flex-wrap flex-v-center flex-h-end flex-box-5-10">
                        <div className="challenge-header-name flex-row flex-v-center">
                            {this.renderBackButton()}

                            {this.props.title}
                        </div>

                        <div className="flex-auto"/>

                        {this.renderGoToPreview()}

                        {this.renderCreateButton()}

                        {this.renderMore()}
                    </div>
                </div>
            </div>
        )
    }
}

Header.defaultProps = {
    user: {
        persona: 'teacher'
    },
    title: '',
    challenge_version: {},
    show_back_button: false,
    show_create_button: false,
    show_preview_button: false
}

Header.propTypes = {
    me: PropTypes.shape({
        persona: PropTypes.string.isRequired
    }),
    title: PropTypes.string,
    challenge_version: PropTypes.shape({
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired
        })
    }),
    show_back_button: PropTypes.bool,
    show_create_button: PropTypes.bool,
    beforeCreate: PropTypes.func,
    afterCreate: PropTypes.func,
    show_preview_button: PropTypes.bool,
    more_to_render: PropTypes.node,
    navigate: PropTypes.func.isRequired
}

export default props => (
    <Header
        {...props}
        navigate={useNavigate()}
        me={useSelector(state => state.me)}
    />
);
