import React from 'react';
import {useSelector} from "react-redux";
import {find} from 'lodash';

export default function ({printable}) {

    const dashboard_filters = useSelector(state => state.dashboard_filters);
    const dashboard_school_filter = useSelector(state => state.dashboard_school_filter);
    const dashboard_student_filter = useSelector(state => state.dashboard_student_filter);

    if (!printable) {
        return null;
    }

    const school_id = dashboard_filters.school_id;
    const student_id = dashboard_filters.student_id;

    if (!student_id.length) {
        return null;
    }

    const schools = school_id.map((id) => {
        const school_data = find(dashboard_school_filter, {school_id: id});
        if (school_data && school_data.school_name) {
            return (
                <div key={id}>
                    {school_data.school_name}
                </div>
            );
        } else {
            return null;
        }
    });

    const students = student_id.map((id) => {
        const student_data = find(dashboard_student_filter, {student_id: id});
        if (student_data && student_data.student_name) {
            return (
                <div key={id}>
                    {student_data.student_name}
                </div>
            );
        } else {
            return null;
        }
    });

    return (
        <div className="type-a-printable-header">
            <div className="type-a-printable-header-school">
                <div className="flex-row flex-wrap flex-gap-5">
                    {schools}
                    <div>{`Individual Student Report`}</div>
                </div>
            </div>

            <div>
                <div className="flex-row flex-wrap flex-gap-5">
                    <div>{`Student Name is`}</div>
                    {students}
                </div>
            </div>
        </div>
    );
}
