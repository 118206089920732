import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get, truncate, capitalize} from 'lodash'
import moment from 'moment'
// import * as microsoftTeams from "@microsoft/teams-js"

import {processStringContent} from 'utils/content'
import {getChallengeHeroThumb} from 'utils/cloud'


class GuestChallengeCard extends Component {
    toPreview() {
        return e => {
            this.props.onPreview()

            // microsoftTeams.settings.setSettings({
            //     websiteUrl: window.location.origin,
            //     contentUrl: window.location.origin + `/teams/challenge_preview/${this.props.challenge_version.code}`,
            // })
        }
    }

    renderChallengeName(name) {
        const name_array = name.replace(': ', ':: ').split(': ')
        return (
            <div className="challenge-title">
                {get(name_array, 0, '')}
                <br/>
                {get(name_array, 1, '')}
            </div>
        )
    }

    renderNewLabel() {
        const {challenge_version} = this.props
        const is_new = moment.unix(challenge_version.challenge_code.created_at).isAfter(moment().add(-90, 'day'))
        if (is_new) {
            return (
                <div className="challenge-new-label z-depth-2" style={{color: challenge_version.challenge_code.color}}>
                    {`* NEW *`}
                </div>
            )
        }
    }

    renderTopics(standards) {
        let topics = [];
        standards.map(standard => {
            if (!topics.includes(standard.topic)) {
                topics.push(standard.topic);
            }
        });

        return (
            <div className="challenge-category">
                {truncate(topics.join(', '), {length: 140, omission: '...'})}
            </div>
        );
    }

    renderStandardCodes(standards) {
        let codes = [];
        standards.map(standard => {
            if (this.props.curriculum && !this.props.curriculum.is_code_shown) {
                if (!codes.includes(standard.grade)) {
                    codes.push(standard.grade);
                }
            } else {
                if (!codes.includes(standard.code)) {
                    codes.push(standard.code);
                }
            }
        });
        codes = codes.join(', ');
        if (this.props.curriculum && !this.props.curriculum.is_code_shown) {
            codes = capitalize(this.props.curriculum.grade_term) + ': ' + codes;
        }

        return (
            <div className="challenge-ccc">
                {truncate(codes, {length: 140, omission: '...'})}
            </div>
        );
    }

    render() {
        const {challenge_version} = this.props;
        const thumb_url = getChallengeHeroThumb(challenge_version.challenge_code.code)
        const standards = challenge_version.challenge_code.standards.filter(standard => this.props.curriculum.id ? standard.curriculum_id === this.props.curriculum.id : standard.curriculum_id === 1);

        return (
            <div className="guest-challenge-card">
                <div className="challenge-triangle" style={{borderBottomColor: challenge_version.challenge_code.color}}>&nbsp;</div>
                {this.renderNewLabel()}
                <div className="challenge-container"
                     style={{backgroundImage: `url(${thumb_url})`}}>
                    <div className="challenge-content-container" style={{backgroundColor: challenge_version.challenge_code.color}}>
                        {this.renderChallengeName(challenge_version.name)}

                        <div className="challenge-description">
                            {
                                truncate(
                                    processStringContent(challenge_version.description, {
                                        convert_html: false,
                                        convert_math: false
                                    }),
                                    {length: 140, omission: '...'}
                                )
                            }
                        </div>

                        {this.renderTopics(standards)}
                        {this.renderStandardCodes(standards)}
                    </div>
                </div>

                <div className="challenge-hover">
                    <button
                        className="btn btn-large btn-on-dark"
                        onClick={this.toPreview()}
                    >
                        {`Preview`}
                    </button>
                </div>
            </div>
        )
    }
}

GuestChallengeCard.propTypes = {
    challenge_version: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
            created_at: PropTypes.number.isRequired,
            standards: PropTypes.arrayOf(PropTypes.shape({
                code: PropTypes.string.isRequired,
                grade: PropTypes.string.isRequired,
                topic: PropTypes.string.isRequired,
                curriculum_id: PropTypes.number.isRequired
            })).isRequired,
        }).isRequired,
    }).isRequired,
    curriculum: PropTypes.object,
    onPreview: PropTypes.func.isRequired
};

export default GuestChallengeCard;
