import React, {Component} from 'react'

import PAMMenuNormal from './PAMMenuNormal'
import PAMMenuMobile from './PAMMenuMobile'

import "./pam_menu.scss";


export default class PAMMenu extends Component {
    render() {
        return (
            <div className="pam-menu">
                <div className="flex-row flex-wrap">
                    <div className="pam-menu-header">
                        <h2 className="header-new">
                            {`PAM Blue (legacy)`}
                        </h2>
                        <h4 className="header-new">
                            {`PAM Blue (legacy)`}
                        </h4>
                    </div>

                    <div className="flex-auto">
                        <PAMMenuNormal />
                    </div>

                    <PAMMenuMobile />
                </div>
            </div>
        )
    }
}
