import React, {Component} from "react";
import PropTypes from "prop-types";

class Announcement extends Component {
    renderClose() {
        if (this.props.announcement.dismissable) {
            return (
                <div className="mindsets-announcement-close">
                    <i className="fas fa-times" onClick={() => this.props.onClose(this.props.announcement)}/>
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div className={"mindsets-announcement mindsets-announcement-" + this.props.index}>
                <div className="container">
                    <div className="center-align" dangerouslySetInnerHTML={{__html: this.props.announcement.body}}/>
                </div>

                {this.renderClose()}
            </div>
        );
    }
}

Announcement.propTypes = {
    announcement: PropTypes.shape({
        id: PropTypes.number.isRequired,
        body: PropTypes.string.isRequired,
        dismissable: PropTypes.bool.isRequired
    }).isRequired,
    index: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired
};

export default Announcement
