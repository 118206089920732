import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

import analytic from 'utils/Analytic';
import PreviewLayerReminder from './PreviewLayerReminder';
import PreviewLayerFinished from './PreviewLayerFinished';
import FeatureLock from 'common/membership/FeatureLock';

function PreviewFrame({goto, children}) {
    const challenge_version = useSelector(state => state.challenge_version);
    const stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const current_index = useSelector(state => state.challenge_preview_current_index);
    const show_reminder = useSelector(state => state.challenge_preview_show_reminder);

    function isFirst() {
        return current_index <= 0;
    }

    function isLast() {
        const total = stream_entities.length;
        return current_index > 0 && current_index >= total - 1;
    }

    function goPrevious() {
        const to_index = current_index - 1;
        goto(to_index);
        analytic.log('previewPrevious', challenge_version, to_index);
    }

    function goNext() {
        const to_index = current_index + 1;
        goto(to_index);
        analytic.log('previewNext', challenge_version, to_index);
    }

    function renderButtonPrevious() {
        if (isFirst()) {
            return null;
        }

        return (
            <div className="challenge-preview-previous">
                <button className="btn btn-large btn-challenge" onClick={goPrevious}>
                    <i className="fas fa-chevron-left"></i>
                </button>
            </div>
        );
    }

    function renderButtonNext() {
        if (isLast()) {
            return null;
        }

        const can_preview_full = true;
        const label = isFirst() ? (<span className="hide-on-small-only">{`Preview`}</span>) : null;

        return (
            <div className="challenge-preview-next">
                <FeatureLock
                    enable={current_index < 3 || can_preview_full}
                    message={'continue previewing'}
                >
                    <button className="btn btn-large btn-challenge" onClick={goNext}>
                        {label}
                        <i className="fas fa-chevron-right"></i>
                    </button>
                </FeatureLock>
            </div>
        );
    }

    function renderPreviewLayerReminder() {
        if (show_reminder) {
            return (
                <PreviewLayerReminder/>
            )
        }
    }

    function renderPreviewLayerFinished() {
        if (isLast()) {
            return (
                <PreviewLayerFinished/>
            )
        }
    }

    return (
        <div className="challenge-preview-container">
            <div className="challenge-preview-content-container">
                {children}
            </div>

            {renderButtonPrevious()}
            {renderButtonNext()}

            {renderPreviewLayerReminder()}
            {renderPreviewLayerFinished()}
        </div>
    );
}

PreviewFrame.propTypes = {
    goto: PropTypes.func.isRequired
}

export default PreviewFrame
