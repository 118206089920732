import React from 'react'
import {
    find, get, isPlainObject, isArray, isString, isNumber, isEmpty, map
} from 'lodash'

import {processStringContent} from 'utils/content'
import {convertLatexToKatex} from 'utils/math'
import {getQuestionID} from 'utils/challenge/challengeComponent'


export const getFormattedAnswer = (
    component_data, questions, responses, polls, user_votes
) => {
    const question_id = getQuestionID(component_data, questions)
    const response = find(responses, {question_id});
    const poll = find(polls, (p) => p.question_id == question_id);

    switch (true) {
        case component_data.type.startsWith('answer.'):
            if (!response) {
                break
            }
            switch (true) {
                case component_data.type == 'answer.graphing':
                    return formatGraphingAnswer(response)
                case component_data.type == 'answer.expression':
                    return formatExpressionAnswer(response)
                case component_data.type == 'answer.expression_multi':
                    return formatExpressionMultiAnswer(response)
                case component_data.type.startsWith('answer.choice_'):
                    return formatChoiceAnswer(response, component_data.data.options)
                case component_data.type == 'answer.fraction':
                    return formatFractionAnswer(response, component_data)
                default:
                    return formatAnswer(response)
            }
            break
        case component_data.type === 'poll.inline':
            if (!poll) {
                break
            }
            if (response) {
                return formatVoteResponse(response, poll)
            }
            break
        case component_data.type === 'poll.block':
            // In case of Poll block, question_id is undefined
            if (!poll) {
                break
            }
            const vote = find(user_votes, (v) => v.poll_id === poll.id)
            if (vote) {
                return formatVote(vote)
            }
            break
        case component_data.type == 'spreadsheet':
            if (response) {
                return formatSpreadsheet(response)
            }
            break
    }
    return undefined
}

export const formatAnswer = (answer_obj) => {
    const answer_content = get(answer_obj, 'data.answer')
    if (answer_content != undefined) {
        switch (true) {
            case get(answer_content, 'text') !== undefined:
                const text = get(answer_content, 'text')
                const content = processStringContent(text, {convert_spelling: false})
                return (
                    <div className="answer-complex">
                        {content}
                    </div>
                )
            case isPlainObject(answer_content):
                return map(answer_content, (a, k) => `${k}: ${a}`).join(', ')
            case isArray(answer_content):
                return answer_content.join(', ')
            case isString(answer_content):
            case isNumber(answer_content):
            default:
                return answer_content
        }
    }
}

export const formatGraphingAnswer = (answer_obj) => {
    const answer_option = get(answer_obj, 'data.answer.answer_option')
    if (answer_option === 'paper') {
        return (
            <div className="answer-graphing">
                <div className="answer-graphing-paper">
                    {`I am using paper.`}
                </div>
            </div>
        )
    } else if (answer_option === 'tool') {
        return (
            <div className="answer-graphing">
                <div className="flex-row flex-between">
                    <div>
                        {`I am using the online tool.`}
                    </div>
                    <div>
                        <a
                            href={`/view/answer/graphing/${answer_obj.id}`}
                            target="_blank"
                        >
                            {`View`}
                        </a>
                    </div>
                </div>
            </div>
        )
    } else {
        return null;
    }
}

export const formatExpressionAnswer = (answer_obj) => {
    const latex = get(answer_obj, 'data.answer')
    const katex = convertLatexToKatex(latex)
    const content = processStringContent(katex, {convert_spelling: false})
    return (
        <div className="answer-expression">
            {content}
        </div>
    )
}

export const formatExpressionMultiAnswer = (answer_obj) => {
    const answer_array = get(answer_obj, 'data.answer')
    const content = answer_array.map((latex, key) => {
        const katex = convertLatexToKatex(latex)
        const content = processStringContent(katex, {convert_spelling: false})
        return (
            <div key={key}>
                <div className="answer-expression">
                    {content}
                </div>
            </div>
        )
    })
    return (
        <div className="answer-expression-multi">
            {content}
        </div>
    )
}

export const formatChoiceAnswer = (answer_obj, options) => {
    const answer_content = get(answer_obj, 'data.answer')
    if (answer_content !== undefined) {
        if (Array.isArray(answer_content)) {
            return answer_content.map(answer => options[answer]).join(', ');
        } else {
            return options[answer_content];
        }
    }
}

export const formatFractionAnswer = (answer_obj, component_data) => {
    const answer_content = get(answer_obj, 'data.answer');

    const fractionPart = (fractionData, fractionKey, inputKey, answer_selections) => {
        const content = get(fractionData, ['texts', inputKey, 'content', 'html']);
        if (content) {
            return processStringContent(content);
        } else {
            return get(answer_selections, [fractionKey, inputKey], '');
        }
    }

    const oneFraction = (fractionData, fractionKey) => {
        const front = fractionPart(fractionData, fractionKey, 'front', answer_content.answer_selections);
        const top = fractionPart(fractionData, fractionKey, 'top', answer_content.answer_selections);
        const bottom = fractionPart(fractionData, fractionKey, 'bottom', answer_content.answer_selections);
        return (
            <div className="flex-row flex-v-center">
                <div className="answer-fraction-front">
                    {front}
                </div>
                <div className="flex-column flex-v-center">
                    <div className="answer-fraction-top">{top}</div>
                    <div className="answer-fraction-divider"></div>
                    <div className="answer-fraction-bottom">{bottom}</div>
                </div>
            </div>
        )
    }

    const items = get(component_data, 'data.items', []);
    let fractionKey = -1;
    const renderItems = items.map((item, itemKey) => {
        if (item.type === 'fraction') {
            fractionKey++;
            return (
                <div key={itemKey}>
                    {oneFraction(item, fractionKey)}
                </div>
            );
        } else if (item.type === 'static') {
            return (
                <div key={itemKey}>
                    {processStringContent(get(item, 'content.html'))}
                </div>
            )
        } else {
            return null;
        }
    })

    if (answer_content !== undefined) {
        return (
            <div className="answer-fraction">
                <div className="flex-row flex-v-center flex-box-2-2">
                    {renderItems}
                </div>
            </div>
        );
    }
}

export const formatSpreadsheet = (response) => {
    const render_content = response.data.answer.map((row, i) => {
        const row_content = []
        map(row, (v, k) => {
            switch (true) {
                case isPlainObject(v) && v.editable:
                    row_content.push(
                        <span key={`${i}_${k}`}>{v.title}</span>
                    )
                    break
                case isString(v):
                    row_content.push(
                        <span key={`${i}_${k}`}>{v}</span>
                    )
                    break
            }
        })

        if (!isEmpty(row_content)) {
            return (
                <div className="byquestion-stream-answer-spreadsheet-row" key={i}>
                    {row_content}
                </div>
            )
        }
    })

    return (
        <div className="byquestion-stream-answer-spreadsheet">
            {render_content}
        </div>
    )
}

export const formatVoteResponse = (response, poll) => {
    const response_content = get(response, 'data.answer')
    const poll_option = poll.poll_options.find(poll_option => poll_option.id === response_content);
    if (poll_option) {
        return processStringContent(poll_option.title)
    }

    return null;
}

export const formatVote = (vote_obj) => {
    return processStringContent(vote_obj.poll_option.title)
}
