import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {processStringContent} from 'utils/content'


class CardTitle extends Component {
    getDefaultTitle() {
        if (this.props.stream_entity.data) {
            return this.props.stream_entity.data.title
        }
    }

    getCardTitle() {
        const {type, data={}, polls} = this.props.stream_entity

        // New data model
        switch (type) {
            case 'intro':
                return 'Introduction'
            case 'welcome':
                return 'Welcome'
            case 'poll':
                return 'Class vote and poll'
            case 'winner':
                return 'Poll Winner'
            case 'complete':
                return 'Challenge Review'
            case 'unlock':
                return 'Bonus Unlocked'
            case 'final':
                return 'Finished!'
            case 'question':
            case 'instruction':
                return this.getDefaultTitle()
        }

        // Old data model
        switch (true) {
            case type == 'intro':
                return 'Introduction'
            case type == 'modal' && data.type == 'welcome':
                return 'Welcome'
            case polls && polls[0] && polls[0].type != 'inline':
                return 'Class vote and poll'
            case type == 'modal' && data.type == 'winner':
                return 'Poll Winner'
            case type == 'rating':
                return 'Challenge Review'
            case type == 'modal' && data.type == 'bonus':
                return 'Bonus Unlocked'
            case data.type == 'final':
                return 'Finished!'
            case type == 'system':
                return this.getDefaultTitle()
        }

        return this.getDefaultTitle()
    }

    getProcessedCardTitle() {
        return processStringContent(this.getCardTitle(), {
            convert_html: false,
            convert_math: false
        })
    }

    render() {
        return (
            <span>
                {this.getProcessedCardTitle()}
            </span>
        )
    }
}

CardTitle.defaultProps = {
}

CardTitle.propTypes = {
    stream_entity: PropTypes.object.isRequired
}

export default CardTitle
