import {
    LOADED_DASHBOARDS,
    LOADED_DASHBOARD,
    SET_DASHBOARD_FILTERS,
    LOADED_DASHBOARD_PRACTICE_FILTER,
    LOADED_DASHBOARD_SCHOOL_FILTER,
    LOADED_DASHBOARD_IMPACT_STUDY_FILTER,
    LOADED_DASHBOARD_GRADE_FILTER,
    LOADED_DASHBOARD_CHALLENGE_FILTER,
    LOADED_DASHBOARD_TEACHER_FILTER,
    LOADED_DASHBOARD_PRACTICE_SKILL_FILTER,
    LOADED_DASHBOARD_STUDENT_FILTER,
    LOADED_DASHBOARD_ACHIEVEMENT_LEVEL_FILTER,
    LOADED_DASHBOARD_FILTER_DEPENDENCIES,
    LOADED_DASHBOARD_SUBJECT_FILTER,
    SET_DASHBOARD_DATA,
    CLEAN_DASHBOARD_DATA
} from "../actions/performance_actions";

export const default_filters = {
    impact_study: '',
    subject: '',
    class_grade: [],
    school_id: [],
    challenge_code: [],
    teacher_id: [],
    achievement_level: [],
    practice_skill_id: [],
    student_id: [],
    class_date: ''
};

const dashboards = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARDS:
            return action.payload;
        default:
            return payload;
    }
};
const insights_dashboard = (payload = {}, action) => {
    switch (action.type) {
        case LOADED_DASHBOARD:
            return action.payload;
        default:
            return payload;
    }
};
const dashboard_filters = (payload = default_filters, action) => {
    switch (action.type) {
        case SET_DASHBOARD_FILTERS:
            return action.payload;
        default:
            return payload;
    }
};

const dashboard_impact_study_filter = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARD_IMPACT_STUDY_FILTER:
            return action.payload;
        default:
            return payload;
    }
};
const dashboard_practice_filter = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARD_PRACTICE_FILTER:
            return action.payload;
        default:
            return payload;
    }
};
const dashboard_subject_filter = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARD_SUBJECT_FILTER:
            return action.payload;
        default:
            return payload;
    }
};
const dashboard_grade_filter = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARD_GRADE_FILTER:
            return action.payload;
        default:
            return payload;
    }
};
const dashboard_school_filter = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARD_SCHOOL_FILTER:
            return action.payload;
        default:
            return payload;
    }
};
const dashboard_challenge_filter = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARD_CHALLENGE_FILTER:
            return action.payload;
        default:
            return payload;
    }
};
const dashboard_teacher_filter = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARD_TEACHER_FILTER:
            return action.payload;
        default:
            return payload;
    }
};
const dashboard_practice_skill_filter = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARD_PRACTICE_SKILL_FILTER:
            return action.payload;
        default:
            return payload;
    }
};
const dashboard_student_filter = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARD_STUDENT_FILTER:
            return action.payload;
        default:
            return payload;
    }
};
const dashboard_achievement_level_filter = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARD_ACHIEVEMENT_LEVEL_FILTER:
            return action.payload;
        default:
            return payload;
    }
};
const dashboard_filter_dependencies = (payload = [], action) => {
    switch (action.type) {
        case LOADED_DASHBOARD_FILTER_DEPENDENCIES:
            return action.payload;
        default:
            return payload;
    }
};

export const default_dashboard_data = {
};

const dashboard_data = (payload = default_dashboard_data, action) => {
    switch (action.type) {
        case SET_DASHBOARD_DATA:
            return {
                ...payload,
                ...action.payload
            };
        case CLEAN_DASHBOARD_DATA:
            return default_dashboard_data;
        default:
            return payload;
    }
};

export default {
    dashboards,
    insights_dashboard,
    dashboard_filters,
    dashboard_impact_study_filter,
    dashboard_practice_filter,
    dashboard_subject_filter,
    dashboard_grade_filter,
    dashboard_school_filter,
    dashboard_challenge_filter,
    dashboard_teacher_filter,
    dashboard_practice_skill_filter,
    dashboard_student_filter,
    dashboard_achievement_level_filter,
    dashboard_filter_dependencies,
    dashboard_data
}
