import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as pdfjsLib from "pdfjs-dist/webpack.mjs";
import {cloneDeep} from "lodash";

import Viewer from './Viewer.js';
import Toolbar from './Toolbar.js';

class PDFViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            scale: 0
        };
    }

    componentDidMount() {
        let loadingTask = pdfjsLib.getDocument(cloneDeep(this.props.url));
        loadingTask.promise.then((doc) => {
            this.viewer.setState({
                doc,
            });
        }, reason => console.error(reason));
    }

    zoomIn() {
        return () => this.setState({scale: Math.round(this.state.scale * 1.1 * 100) / 100});
    }

    zoomOut() {
        return () => this.setState({scale: Math.round(this.state.scale / 1.1 * 100) / 100});
    }

    onDownload() {
        return () => {
            let a = document.createElement('a');
            const blob = new Blob([this.props.url], {'type': 'application/pdf'});
            a.href = window.URL.createObjectURL(blob);
            a.download = this.props.fileName;
            a.click();
        };
    }

    displayScaleChanged() {
        return scale => this.setState({scale});
    }

    render() {
        return (
            <div className="pdf-container">
                <Toolbar
                    scale={this.state.scale}
                    onZoomIn={this.zoomIn()}
                    onZoomOut={this.zoomOut()}
                    onDownload={this.onDownload()}
                />
                <div className="pdf-body" id="pdf-body">
                    <Viewer
                        ref={(ref) => this.viewer = ref}
                        scale={this.state.scale}
                        onScaleChanged={this.displayScaleChanged()}/>
                </div>
            </div>
        );
    }
}

PDFViewer.propTypes = {
    url: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
            data: PropTypes.string
        })
    ]),
    fileName: PropTypes.string.isRequired
};

export default PDFViewer;
