import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import {isBonus} from 'utils/challenge/streamEntity'


class CardContainer extends Component {
    renderBg() {
        if (isBonus(this.props.stream_entity)) {
            return (
                <div className="card-container-bg card-container-bg-bonus"></div>
            )
        } else if (this.props.use_challenge_color) {
            return (
                <div className="card-container-bg" style={{backgroundColor: this.props.challenge_version.challenge_code.color}}></div>
            )
        }
    }

    renderHero() {
        if (this.props.hero_image) {
            return (
                <div className="card-container-hero" style={{backgroundImage: `url(${this.props.hero_image})`}}>
                    <div className="card-container-slope" style={{backgroundColor: this.props.challenge_version.challenge_code.color}}>
                    </div>
                </div>
            )
        }
    }

    render() {
        const class_name = classNames(
            'challenge-card-container',
            {
                'use-challenge-color': this.props.use_challenge_color && !isBonus(this.props.stream_entity)
            }
        )

        return (
            <div className={class_name}>
                {this.renderBg()}

                {this.renderHero()}

                <div className="container">
                    <div className="challenge-card-min-height flex-row flex-v-center"
                         style={{minHeight: this.props.min_height}}>
                        <div className="flex-1">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CardContainer.propsTypes = {
    challenge_version: PropTypes.shape({
        challenge_code: PropTypes.shape({
            color: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    stream_entity: PropTypes.object.isRequired,
    use_challenge_color: PropTypes.bool,
    hero_image: PropTypes.string,
    min_height: PropTypes.string,
}

export default CardContainer
