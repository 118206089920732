import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import ReactTooltip from "react-tooltip";

import {getChallengeHeroThumb, getChallengeKidImage} from 'utils/cloud';

import './challenge_tile.scss';


class ChallengeTile extends Component {
    getChallengeImageUrl() {
        return getChallengeHeroThumb(this.props.challenge_version.challenge_code.code)
    }

    renderKid() {
        if (this.props.show_kid) {
            const kid_url = getChallengeKidImage(this.props.challenge_version.challenge_code.code)
            return (
                <div className="challenge-tile-kid">
                    <img src={kid_url} alt='challenge-tile-kid-icon '/>
                </div>
            );
        }

        return null;
    }

    renderChallengeName() {
        const name_array = this.props.challenge_version.name.replace(': ', ':: ').split(': ')
        return (
            <div>
                {get(name_array, 0, '')}
                <br/>
                {get(name_array, 1, '')}
            </div>
        )
    }

    renderDescription() {
        return (
            <div className="challenge-tile-description">
                {this.props.description}
            </div>
        )
    }

    renderButton() {
        if (this.props.button_label && this.props.buttonOnClick) {
            return (
                <button className="btn btn-challenge" onClick={this.props.buttonOnClick}>
                    {this.props.button_label}
                </button>
            )
        }

        return null;
    }

    renderMark() {
        if (this.props.is_done) {
            return (
                <div className='mark-done-tick center'>
                    <i
                        className={'fas fa-check-circle fa-4x'}
                        data-tip='Class completed'
                    />
                    <ReactTooltip/>
                </div>
            );
        }

        return null;
    }

    render() {
        return (
            <div className="challenge-tile">
                <div className="challenge-tile-triangle" style={{borderBottomColor: this.props.challenge_version.challenge_code.color}}>
                    &nbsp;
                </div>

                {this.renderKid()}

                <div className="challenge-tile-container"
                     style={{backgroundImage: `url(${this.getChallengeImageUrl()})`}}>
                    <div className="challenge-tile-content-container"
                         style={{backgroundColor: this.props.challenge_version.challenge_code.color}}>
                        <h5 className="header-new">
                            {this.renderChallengeName()}
                        </h5>

                        <div className="flex-row flex-v-center flex-between">
                            {this.renderDescription()}

                            {this.renderButton()}
                        </div>
                    </div>
                </div>
                {this.renderMark()}
            </div>
        )
    }
}

ChallengeTile.defaultProps = {
    show_kid: false,
    is_done: false,
}

ChallengeTile.propTypes = {
    challenge_version: PropTypes.shape({
        name: PropTypes.string.isRequired,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    show_kid: PropTypes.bool,
    description: PropTypes.string,
    button_label: PropTypes.string,
    buttonOnClick: PropTypes.func,
    is_done: PropTypes.bool,
}

export default ChallengeTile
