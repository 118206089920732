import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import moment from 'moment';
import MindsetsJsSDK, {actions, toastActions} from "mindsets-js-sdk";

import analytic from 'utils/Analytic'
import {getContentStreamEntity, plainTextToImmersiveReader, objectsDiff} from 'utils/immersiveReader';

// import * as ImmersiveReader from '@microsoft/immersive-reader-sdk'


class ImmersiveReaderSwitch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            token: undefined,
            icon: 2,
            properties: {
                adjectiveHighlightingColor: "MiddleGreen",
                adjectiveHighlightingEnabled: false,
                adverbHighlightingColor: "MiddleYellow",
                adverbHighlightingEnabled: false,
                fontFamily: "Calibri",
                formattingEnabled: true,
                nounHighlightingColor: "MiddleMagenta",
                nounHighlightingEnabled: false,
                pictureDictionaryEnabled: true,
                posLabelsEnabled: false,
                syllabificationEnabled: false,
                textSize: 42,
                textSpacing: 40,
                theme: "Light",
                themeSetByUser: false,
                verbHighlightingColor: "MiddleRed",
                verbHighlightingEnabled: false,
                readAloudSpeed: 1,
                voice: "Female",
                shouldTranslateWords: false,
                translationLanguage: null
            }
        }
    }

    getMSCSToken() {
        return new Promise((resolve, reject) => {
            if (this.state.token && moment(this.state.token.expires_on * 1000).isAfter(moment())) {
                resolve(this.state.token)
            } else {
                reject('MSCS token missing.')
            }
        }).catch(() => {
            this.props.dispatch(actions.LoadingAction(true));
            return MindsetsJsSDK().Api.mscs.index().then((response) => {
                this.props.dispatch(actions.LoadingAction(false));
                this.setState({token: response.token})
                return response.token
            })
        }).catch(error => {
            this.props.dispatch(actions.LoadingAction(false));
            this.props.dispatch(toastActions.ErrorMessageAction(error, true));

            throw error;
        })
    }

    getContent() {
        if (this.props.content) {
            return plainTextToImmersiveReader(this.props.challenge_version, this.props.content)
        } else if (this.props.stream_entity) {
            return getContentStreamEntity(this.props.challenge_version, this.props.stream_entity)
        } else {
            return undefined
        }
    }

    onPreferencesChangedCallback() {
        return string => {
            let object = JSON.parse(string);
            if (object.translationState.translationLanguage) {
                object.translationState.translationLanguage = JSON.parse(decodeURIComponent(object.translationState.translationLanguage)).text;
            }
            let data = Object.assign({}, object.displayOptionsState, object.readAloudState, object.translationState);
            const result = objectsDiff(this.state.properties, data);
            const key = Object.keys(result)[0];
            if (key && result[key] && result[key] !== '') {
                let properties = this.state.properties;
                analytic.log('immersiveReaderChanged', {
                    challenge_name: this.props.challenge_version.name,
                    stream_id: !!this.props.stream ? this.props.stream.id : null,
                    class_code: !!this.props.stream ? this.props.stream.lesson.code : null,
                    from: this.props.from,
                    key,
                    before: properties[key],
                    after: result[key]
                });
                properties[key] = result[key];
                this.setState({properties});
            }
        }
    }

    onExitCallback() {
        return () => {
            const {challenge_version, stream_entity, from} = this.props;
            analytic.log('immersiveReaderClosed', {
                name: challenge_version.name,
                code: challenge_version.challenge_code.code
            }, stream_entity, from);
        }
    }

    openImmersiveReader() {
        return (token) => {
            return ImmersiveReader.launchAsync(
                token.access_token,
                'mindsets-immersive-reader',
                this.getContent(),
                {
                    onPreferencesChanged: this.onPreferencesChangedCallback(),
                    onExit: this.onExitCallback()
                }
            ).catch(() => this.props.dispatch(toastActions.ErrorMessageAction('Open Reader service in not available at the moment. Please reload the page and try again or try it later.', true)))
        }
    }

    onClick() {
        return e => {
            this.getMSCSToken().then(this.openImmersiveReader())
            const {challenge_version, stream_entity, from} = this.props
            analytic.log('immersiveReaderOpened', {
                name: challenge_version.name,
                code: challenge_version.challenge_code.code
            }, stream_entity, from)
        }
    }

    renderButton() {
        if (this.props.tiny) {
            return (
                <div className="immersive-reader-tiny">
                    <button className="btn btn-flat btn-circle" onClick={this.onClick()}>
                        <img
                            src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_immersive_reader_1.svg`}
                            alt={`Immersive Reader`}/>
                    </button>
                </div>
            )
        } else {
            return (
                <div
                    className="immersive-reader-normal"
                    onMouseOver={() => this.setState({icon: 1})}
                    onMouseOut={() => this.setState({icon: 2})}>
                    <button className="btn btn-on-dark" onClick={this.onClick()}>
                        <img
                            src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_immersive_reader_${this.state.icon}.svg`}
                            alt={`Immersive Reader`}/>
                        <div className="hide-on-small-only">
                            {`Open Reader`}
                        </div>
                    </button>
                </div>
            )
        }
    }

    render() {
        return (
            <div className="immersive-reader">
                {this.renderButton()}
            </div>
        )
    }
}

ImmersiveReaderSwitch.defaultProps = {
    tiny: false
}

ImmersiveReaderSwitch.propTypes = {
    challenge_version: PropTypes.shape({
        name: PropTypes.string.isRequired,
        challenge_code: PropTypes.shape({
            code: PropTypes.string.isRequired
        }).isRequired
    }).isRequired,
    stream_entity: PropTypes.object,
    stream: PropTypes.shape({
        id: PropTypes.number.isRequired,
        lesson: PropTypes.shape({
            code: PropTypes.string.isRequired
        }).isRequired
    }),
    from: PropTypes.string,
    content: PropTypes.string,
    tiny: PropTypes.bool,
    dispatch: PropTypes.func.isRequired
}

export default props => (
    <ImmersiveReaderSwitch
        {...props}
        dispatch={useDispatch()}
    />
);
