import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import {DATE_FORMAT} from 'constants/index'
import ClassReport from './ClassReport'


class Lesson extends Component {
    renderCompletionStats() {
        const calculatePercentage = (n) => {
            if (this.props.lesson.no_of_student_streams <= 0) {
                return 0
            } else {
                return Math.round(
                    n / this.props.lesson.no_of_student_streams * 100
                )
            }
        }

        const renderPart = (type, p) => {
            if (p > 0) {
                const class_names = classNames('sam-t-completion-bar', `sam-t-completion-${type}`)
                return [
                    (
                        <div key={`${type}-${p}`} className={class_names} style={{flex: p}} title={type}>
                        </div>
                    ),
                    (
                        <div key={`${type}-t`} className="sam-t-completion-text" title={type}>
                            {`${p}%`}
                        </div>
                    )
                ]
            } else {
                return null
            }
        }

        const completion_stats = this.props.lesson.completion_stats
        const p_started = calculatePercentage(completion_stats.stuck)
        const p_underway = calculatePercentage(completion_stats.underway)
        const p_nearly = calculatePercentage(completion_stats.nearly)
        const p_completed = calculatePercentage(completion_stats.completed)
        const p_completedbonus = calculatePercentage(completion_stats.completed_bonus)

        return (
            <div className="sam-t-completion flex-auto flex-row flex-v-center flex-h-end">
                {renderPart('started', p_started)}
                {renderPart('underway', p_underway)}
                {renderPart('nearly', p_nearly)}
                {renderPart('completed', p_completed)}
                {renderPart('completedbonus', p_completedbonus)}
            </div>
        )
    }

    renderLesson() {
        const lesson = this.props.lesson
        const date_text = moment.unix(lesson.created_at).format(DATE_FORMAT)
        return (
            <div className="flex-row">
                <div className="sam-t-lesson-code">
                    {lesson.code.toUpperCase()}
                </div>
                <div className="sam-t-lesson-report">
                    <ClassReport
                        lesson_id={lesson.id}
                        lesson_code={lesson.code}
                        challenge_version_id={lesson.challenge_version_id}
                        challenge_name={lesson.challenge_name}
                        challenge_data_version={lesson.challenge_data_version}
                    />
                </div>
                <div className="sam-t-lesson-challenge">
                    {lesson.challenge_name}
                </div>
                <div className="sam-t-lesson-date">
                    {date_text}
                </div>
                <div className="sam-t-lesson-mode">
                    {lesson.mode}
                </div>
                <div className="sam-t-lesson-students">
                    {lesson.no_of_student_streams}
                </div>
                {this.renderCompletionStats()}
            </div>
        )
    }

    render() {
        return (
            <div className="sam-t-lesson">
                {this.renderLesson()}
            </div>
        )
    }
}

Lesson.propTypes = {
    lesson: PropTypes.object.isRequired
}

export default Lesson
