import React, {Component} from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery';


class ShareToTeams extends Component {
    componentDidMount() {
        $(document).ready(function () {
            shareToMicrosoftTeams.renderButtons()
        });
    }

    getAssignmentTitle() {
        return `Start the Mindsets ${this.props.challenge_version.name} Challenge`
    }

    getAssignmentInstruction() {
        const code = this.props.lesson.code.toUpperCase()
        return `${this.props.challenge_version.description}`
            // + ` Sign in to Mindsets and enter Mindsets Code: ${code} to start.`
    }

    render() {
        return (
            <div
                className="teams-share-button"
                data-href={`${window.location.origin}/join/${this.props.lesson.code}`}
                data-icon-px-size="40"
                data-preview="true"
                data-msg-text={this.getAssignmentInstruction()}
                data-assign-title={this.getAssignmentTitle()}
                data-assign-instr={this.getAssignmentInstruction()}
            >
            </div>
        )
    }
}

ShareToTeams.propTypes = {
    challenge_version: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired
    }).isRequired,
    lesson: PropTypes.object.isRequired
}

export default ShareToTeams
