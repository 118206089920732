import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {useSelector} from "react-redux";
import classNames from 'classnames'

import CardTitle from './CardTitle'
import {getCardName} from 'utils/challenge/streamEntity'
import {userCan} from "utils/membership";


class CardMenu extends Component {
    renderCardFull(se, card_index) {
        const card_number = getCardName(this.props.challenge_version, card_index, '')
        const {complete_index, final_index} = this.props.challenge_version
        if (card_index === final_index || card_index === complete_index + 1) {
            return (
                <div>
                    <span>
                        {card_number}
                    </span>
                </div>
            )
        } else {
            return (
                <div>
                    <span>
                        {`${card_number}. `}
                    </span>
                    <CardTitle
                        stream_entity={se}
                    />
                </div>
            )
        }
    }

    renderRow(se, i) {
        const class_name = classNames('card-menu-row', {
            'card-menu-row-active': i === this.props.selected_index
        })

        return (
            <div
                className={class_name}
                key={se.id}
                onClick={this.props.onClick(i)}
            >
                {this.renderCardFull(se, i)}
            </div>
        )
    }

    renderRows() {
        let stream_entities = this.props.stream_entities;
        if (this.props.is_preview) {
            const can_preview_full = !!this.props.me && (userCan(this.props.me.subscription_status, 'preview_full_challenge', {challenge_version: this.props.challenge_version}) && this.props.challenge_version.is_locked !== true || this.props.me.persona === 'student');
            if (!can_preview_full) {
                stream_entities = stream_entities.filter((se, i) => i <= 3);
            }
        }
        return stream_entities.map((se, i) => {
            return this.renderRow(se, i)
        })
    }

    render() {
        return (
            <div className="card-menu">
                {this.renderRows()}
            </div>
        )
    }
}

CardMenu.defaultProps = {
    onClick: (i) => (e) => undefined,
    is_preview: false
}

CardMenu.propTypes = {
    challenge_version: PropTypes.shape({
        complete_index: PropTypes.number.isRequired,
        final_index: PropTypes.number.isRequired,
        is_locked: PropTypes.bool
    }).isRequired,
    stream_entities: PropTypes.array.isRequired,
    selected_index: PropTypes.number,
    is_preview: PropTypes.bool,
    onClick: PropTypes.func,
    me: PropTypes.shape({
        persona: PropTypes.string.isRequired,
        subscription_status: PropTypes.object
    })
}

export default function (props) {
    const me = useSelector(state => state.me);

    return <CardMenu {...props} me={me}/>
}
