import React from 'react';
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import FeatureLock from "common/membership/FeatureLock";
import {userCan} from 'utils/membership';
import analytic from "utils/Analytic";

function Resource({resource}) {
    const me = useSelector(state => state.me);
    const challenge_version = useSelector(state => state.challenge_version);

    let user_can_see = challenge_version.challenge_code.is_free && resource.show_for_free || me.subscription_status.subscribed;
    if (resource.title == 'Project Extension') {
        user_can_see = challenge_version.challenge_code.is_free && resource.show_for_free || userCan(me.subscription_status, 'download_project_extension');
    }

    function renderLinks() {
        if (Array.isArray(resource.links) && resource.links.length) {
            return (
                <div className='mt-3 flex-row flex-box-0-5'>
                    {resource.links.map((link, index) => {
                        return (
                            <FeatureLock
                                key={index}
                                enable={user_can_see && !challenge_version.is_locked}
                                message={challenge_version.is_locked ? 'Please use the Challenges selected by your district administrator. This Challenge is not selected at this time' : 'unlock this resource'}
                                upgrade_message={challenge_version.is_locked ? '' : undefined}
                                onClickCallback={() => {
                                    if (challenge_version.is_locked) {
                                        analytic.log('openChallengeLock', {
                                            challenge_code: challenge_version.challenge_code.code,
                                            challenge_name: challenge_version.name,
                                            challenge_version: challenge_version.version,
                                            element: resource.title
                                        });
                                    }
                                }}
                                show_pricing_message={!challenge_version.is_locked}
                            >
                                <a href={link.url} className='btn btn-on-white' target='_blank'>
                                    <i className='fas fa-globe'/>
                                    <span>&nbsp;</span>
                                    {link.title}
                                </a>
                            </FeatureLock>
                        );
                    })}
                </div>
            );
        }

        return null;
    }

    function renderName() {
        if (resource.name) {
            return (
                <strong>{resource.name}</strong>
            );
        }

        return null;
    }

    return (
        <div className='challenge-resource flex-row'>
            <div
                style={{backgroundColor: resource.background_color}}
                className='flex-row flex-h-center flex-v-center resource-icon'>
                <i className={`fas fa-${resource.thumbnail} fa-4x text-white`}/>
            </div>
            <div className='p-2'>
                <div className='flex-row mb-2'>
                    <FeatureLock
                        enable={user_can_see && !challenge_version.is_locked}
                        message={challenge_version.is_locked ? 'Please use the Challenges selected by your district administrator. This Challenge is not selected at this time' : 'unlock this resource'}
                        upgrade_message={challenge_version.is_locked ? '' : undefined}
                        onClickCallback={() => {
                            if (challenge_version.is_locked) {
                                analytic.log('openChallengeLock', {
                                    challenge_code: challenge_version.challenge_code.code,
                                    challenge_name: challenge_version.name,
                                    challenge_version: challenge_version.version,
                                    element: resource.title
                                });
                            }
                        }}
                        show_pricing_message={!challenge_version.is_locked}
                    >
                        <a
                            href={resource.url}
                            target='_blank'
                            className='btn btn-challenge no-wrap'
                        >
                            <i className={`fas fa-download text-white`}/>
                            &nbsp;
                            {resource.title}
                        </a>
                    </FeatureLock>
                </div>
                {renderName()}
                <div>{resource.description}</div>
                {renderLinks()}
            </div>
        </div>
    );
}

Resource.propTypes = {
    resource: PropTypes.shape({
        category: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        name: PropTypes.string,
        url: PropTypes.string.isRequired,
        background_color: PropTypes.string.isRequired,
        thumbnail: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        links: PropTypes.arrayOf(PropTypes.shape({
            url: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            language: PropTypes.string,
        })),
        show_for_free: PropTypes.bool
    })
};

export default Resource;
