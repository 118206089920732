import React, {Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTooltip from "react-tooltip";

import {DATE_FORMAT} from 'constants/index';

import './lesson_row.scss';

class LessonRow extends Component {
    renderAnswerKeysState() {
        if (this.props.challenge_version.answer_key_url && this.props.challenge_version.bonus_answer_key_url) {
            return (
                <div className='flex-row flex-h-start flex-v-center'>
                    <i className="action-icon fas fa-key mr-2"/>
                    <div className=''>Answer Keys {this.props.lesson.is_answer_keys_shown ? 'ON' : 'OFF'}</div>
                </div>
            );
        }

        return null;
    }

    renderInfo() {
        const {lesson} = this.props
        const created_at = moment(lesson.created_at * 1000).format(DATE_FORMAT)
        return (
            <div className="flex-row flex-wrap flex-box-0-5">
                <div>{`Created ${created_at}`}</div>
                <div>{`${lesson.students_count} student${lesson.students_count === 1 ? '' : 's'} joined`}</div>
                {this.renderAnswerKeysState()}
            </div>
        )
    }

    renderChallengeName() {
        if (this.props.show_challenge_name) {
            return (
                <div className='lessonlist-lesson-challengename'>{this.props.challenge_version.name}</div>
            );
        }
    }

    renderBigMark() {
        if (this.props.lesson.is_done) {
            return (
                <div className='mark-done-tick flex-row flex-v-center hide-on-small-only'>
                    <i
                        className={'far fa-check-circle fa-4x mark-done-tick-icon'}
                        data-tip='Class completed'
                    />
                    <ReactTooltip/>
                </div>
            );
        }

        return null;
    }

    renderSmallMark() {
        if (this.props.lesson.is_done) {
            return (
                <div>
                    <i
                        className={'far fa-check-circle fa-lg mark-done-tick-icon'}
                        data-tip='Class completed'
                    />
                    <ReactTooltip/>
                </div>
            );
        }

        return null;
    }

    render() {
        const {lesson} = this.props;

        return (
            <div className={"lessonlist-lesson flex-row "}>
                <div className="lessonlist-lesson-classcode hide-on-small-only flex-row flex-v-center">
                    {lesson.code}
                </div>

                <div className="lessonlist-lesson-classname flex-auto">
                    <div className="lessonlist-lesson-classcode hide-on-med-and-up">
                        {lesson.code} {this.renderSmallMark()}
                    </div>
                    {this.renderChallengeName()}

                    <div className="lessonlist-lesson-classname-name flex-row">{this.props.lesson.name}</div>

                    {this.renderInfo()}
                </div>

                {this.renderBigMark()}
            </div>
        );
    }
}

LessonRow.defaultProps = {
    show_challenge_name: false
}

LessonRow.propTypes = {
    challenge_version: PropTypes.shape({
        name: PropTypes.string.isRequired,
        answer_key_url: PropTypes.string,
        bonus_answer_key_url: PropTypes.string
    }).isRequired,
    lesson: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
        is_done: PropTypes.bool.isRequired,
        created_at: PropTypes.number.isRequired,
        students_count: PropTypes.number.isRequired,
        is_answer_keys_shown: PropTypes.bool.isRequired
    }).isRequired,
    show_challenge_name: PropTypes.bool
};

export default LessonRow;
