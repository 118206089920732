import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get} from 'lodash'

import Popup from 'common/Popup'
import CardMenu from 'common/challenge/CardMenu'
import {getCardName} from 'utils/challenge/streamEntity'
import analytic from 'utils/Analytic'


class CardSelector extends Component {
    onSelect() {
        return (i) => (e) => {
            e.preventDefault()
            if (i != this.props.selected_card_index) {
                this.props.onSelect(i)
            }
            this.refs.popup.toggle(false)(e)

            analytic.log('cardSelectorOnSelect', this.props.selected_card_index)
        }
    }

    renderPopup() {
        return (
            <div className="challenge-card-selector-popup">
                <CardMenu
                    challenge_version={this.props.challenge_version}
                    stream_entities={this.props.stream_entities}
                    selected_index={this.props.selected_card_index}
                    onClick={this.onSelect()}
                    is_preview={this.props.is_preview}
                />
            </div>
        )
    }

    renderCurrent() {
        return (
            <div className="challenge-card-selector-current flex-row flex-h-center">
                <Popup ref="popup" popup_content={this.renderPopup()} {...this.props.popup_setting}>
                    <button className="btn btn-grey">
                        <span>
                            {getCardName(this.props.challenge_version, this.props.selected_card_index)}
                        </span>
                        <i className="fas fa-caret-down"></i>
                    </button>
                </Popup>
            </div>
        )
    }

    render() {
        return (
            <div className="challenge-card-selector flex-row">
                {this.renderCurrent()}
            </div>
        )
    }
}

CardSelector.defaultProps = {
    popup_setting: {
        right: '0px'
    },
    is_preview: false
}

CardSelector.propTypes = {
    challenge_version: PropTypes.object.isRequired,
    stream_entities: PropTypes.array.isRequired,
    selected_card_index: PropTypes.number.isRequired,
    is_preview: PropTypes.bool,
    onSelect: PropTypes.func.isRequired,
    popup_setting: PropTypes.object
}

export default CardSelector
