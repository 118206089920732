import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './avatar_name.scss';


class AvatarName extends Component {
    renderAvatar() {
        return (
            <div className="avatar-name-avatar flex-row flex-vh-center">
                <div className="avatar-name-avatar-icon">
                    <div className={`cashtivity-icon ${this.props.user.avatar}`}></div>
                </div>

                <div className="avatar-name-private-icon">
                    <i className="avatar-name-private-icon fas fa-user-graduate"></i>
                </div>
            </div>
        )
    }

    renderName() {
        return (
            <div className="avatar-name-name">
                {this.props.user.full_name}
            </div>
        )
    }

    render() {
        return (
            <div className="user-avatar-name">
                <div className={this.props.private_mode ? 'private-mode' : 'not-private-mode'}>
                    <div className="flex-row flex-v-center">
                        {this.renderAvatar()}

                        <div className="flex-column">
                            <div className="flex-auto flex-row flex-v-center">
                                {this.renderName()}

                                <div className="user-avatar-name-content-right">
                                    {this.props.content_right}
                                </div>
                            </div>

                            <div className="user-avatar-name-content-bottom">
                                {this.props.content_bottom}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

AvatarName.propTypes = {
    user: PropTypes.object.isRequired,
    private_mode: PropTypes.bool.isRequired,
    content_right: PropTypes.node,
    content_bottom: PropTypes.node
}

export default AvatarName
