import React from 'react';
import {useSelector} from "react-redux";
import {get} from 'lodash';

import ToggleAnswerKeysAction from 'common/ToggleAnswerKeysAction';
import ToggleLessonModeAction from 'common/ToggleLessonModeAction';


export default function () {
    const me = useSelector(state => state.me);
    const lesson = useSelector(state => state.lesson);

    function renderAnswerKeyToggle() {
        return (
            <div className="setup-class-options-option">
                <ToggleAnswerKeysAction
                    lesson={lesson}
                    asToggle={true}
                    bonus_answer_key_url={get(lesson, 'challenge_version.bonus_answer_key_url')}
                    answer_key_url={get(lesson, 'challenge_version.answer_key_url')}
                    showLinks={true}
                />
            </div>
        );
    }

    function renderModeToggle() {
        return (
            <div className="setup-class-options-option">
                <ToggleLessonModeAction
                    lesson={lesson}
                    mode='tiles'
                />
            </div>
        );
    }

    if (me && lesson.user_id === me.id) {
        return (
            <div className="setup-class-options">
                <div className='flex-row flex-box-0-5 flex-v-end'>
                    <h4 className="header-new mr-1">Teaching mode</h4>
                    <a href='https://fast.wistia.com/embed/channel/sc32gs6ywb?wchannelid=sc32gs6ywb&wmediaid=i99fkwf5g5' className='learn-more'
                       target='_blank'>Learn more.</a>
                </div>

                <div className="section">
                    {/*{renderAnswerKeyToggle()}*/}
                    {renderModeToggle()}
                </div>
            </div>
        );
    }

    return null;
}
