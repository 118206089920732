import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {get, map, capitalize, toInteger, find} from 'lodash'

import {MEASURES} from 'constants/index'
import CurriculumSelector from 'common/CurriculumSelector'


class ChallengesFilter extends Component {
    renderSubject() {
        const onChange = (event) => {
            this.props.setStateValue('selected_subject', event.target.value);
        }
        return (
            <div className="">
                <select
                    value={this.props.selected_subject}
                    onChange={onChange}
                    className=""
                >
                    <option key='math' value='math'>
                        {`Math Challenges`}
                    </option>
                    <option key='science' value='science'>
                        {`Science Challenges`}
                    </option>
                </select>
            </div>
        )
    }

    renderCurriculum() {
        const onChange = (c_id) => {
            this.props.setStateValue('selected_curriculum', toInteger(c_id));
        }
        return (
            <CurriculumSelector
                onCurriculumIdChange={onChange}
                validation_error_messages={{}}
                curriculums={this.props.curriculums}
                curriculum_id={this.props.selected_curriculum}
            />
        )
    }

    renderMeasure() {
        const onChange = (event) => {
            this.props.setStateValue('selected_measure', event.target.value);
        }
        const options = map(Object.keys(MEASURES), (key) => {
            return (
                <option key={key} value={key}>{MEASURES[key]}</option>
            )
        })
        return (
            <div className="">
                <select
                    value={this.props.selected_measure}
                    onChange={onChange}
                    className=""
                >
                    {options}
                </select>
            </div>
        );
    }

    renderTopic() {
        const onChange = (event) => {
            this.props.setStateValue('selected_topic', event.target.value);
        }
        const options = map(this.props.topics, (topic, key) => {
            return (
                <option key={key} value={topic}>{topic}</option>
            )
        })
        return (
            <div className="">
                <select
                    value={this.props.selected_topic}
                    onChange={onChange}
                    className=""
                    disabled={this.props.topics.length <= 0}
                >
                    <option key='empty' value=''>
                        {`Topic (All)`}
                    </option>
                    {options}
                </select>
            </div>
        );
    }

    renderGrade() {
        const onChange = (event) => {
            this.props.setStateValue('selected_grade', event.target.value);
        }
        const options = map(this.props.grades, (grade, key) => {
            return (
                <option key={key} value={grade}>{grade}</option>
            )
        })
        const grade_label = capitalize(
            get(
                find(this.props.curriculums, {id: this.props.selected_curriculum}),
                'grade_term',
                'grade'
            )
        )
        return (
            <div className="">
                <select
                    value={this.props.selected_grade}
                    onChange={onChange}
                    className=""
                    disabled={this.props.grades.length <= 0}
                >
                    <option key='empty' value=''>
                        {`${grade_label} (All)`}
                    </option>
                    {options}
                </select>
            </div>
        );
    }

    renderTheme() {
        const onChange = (event) => {
            this.props.setStateValue('selected_theme', event.target.value);
        }
        const options = map(this.props.themes, (theme, key) => {
            return (
                <option key={key} value={theme}>{theme}</option>
            )
        })
        return (
            <div className="">
                <select
                    value={this.props.selected_theme}
                    onChange={onChange}
                    className=""
                    disabled={this.props.themes.length <= 0}
                >
                    <option key='empty' value=''>
                        {`Theme (All)`}
                    </option>
                    {options}
                </select>
            </div>
        );
    }

    render() {
        return (
            <div className="teams-c-l-challenges-filter">
                <div className="flex-row flex-v-center flex-box-5-5 flex-wrap">
                    {this.renderSubject()}
                    {this.renderCurriculum()}
                    {this.renderGrade()}
                    {this.renderTheme()}
                </div>
            </div>
        );
    }
}

ChallengesFilter.propTypes = {
    curriculums: PropTypes.array.isRequired,
    topics: PropTypes.array.isRequired,
    grades: PropTypes.array.isRequired,
    themes: PropTypes.array.isRequired,
    selected_curriculum: PropTypes.number,
    selected_topic: PropTypes.string.isRequired,
    selected_grade: PropTypes.string.isRequired,
    selected_theme: PropTypes.string.isRequired,
    selected_measure: PropTypes.string.isRequired,
    setStateValue: PropTypes.func.isRequired,
};

export default ChallengesFilter;
