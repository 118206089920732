import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    datesConstants,
    ErrorMessageAction,
    LoadDistrictChallengeLocksAction,
    DeleteChallengeLockAction,
    CreateDistrictChallengeLockAction, DatePicker
} from "mindsets-js-sdk";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from "moment/moment";

const COLORS = ['cadetblue', 'blueviolet', 'coral', 'crimson', 'darkcyan', 'darkgoldenrod', 'darkslateblue', 'firebrick', 'indianred', 'indigo', 'maroon', 'midnightblue', 'olive', 'peru', 'royalblue', 'seagreen', 'sienna', 'steelblue', 'teal'];

export default function () {
    const dispatch = useDispatch();
    const district = useSelector(state => state.district);
    const challenge_codes = useSelector(state => state.challenge_codes);
    const challenge_locks = useSelector(state => state.district_challenge_locks);
    const [show, setShow] = useState(false);
    const [challenge_code_id, setChallengeCodeId] = useState('');
    const [end_date, setEndDate] = useState(null);
    const [message, setMessage] = useState('');

    function fetchChallengeLocks() {
        return dispatch(LoadDistrictChallengeLocksAction(district.id, {
            'selectors[0]': 'latest_challenge_version_name',
            limit: 0
        }));
    }

    function handleEventClick(clickInfo) {
        if (confirm(`Are you sure you want to delete the Challenge Lock for '${clickInfo.event.title}'`)) {
            dispatch(DeleteChallengeLockAction(clickInfo.event.id))
                .then(fetchChallengeLocks)
                .catch(error => {
                    dispatch(ErrorMessageAction('Could not delete selected Challenge Lock. Please try refreshing the page.'));
                    console.error(error);
                });
        }
    }

    function createChallengeLock() {
        dispatch(CreateDistrictChallengeLockAction({
            challenge_code_id,
            end_date,
            message
        }))
            .then(() => {
                setShow(false);
                setChallengeCodeId('');
                setEndDate(null);
                setMessage('');
            })
            .then(fetchChallengeLocks)
            .catch(error => {
                dispatch(ErrorMessageAction('Could not add a Challenge Lock. Please try refreshing the page.'));
                console.error(error);
            });
    }

    function renderChallengeLocks() {
        if (challenge_locks.length > 0) {
            let color_index = 0;

            return (
                <div>
                    <div>Challenge Locks found: {challenge_locks.length}</div>
                    <FullCalendar
                        plugins={[dayGridPlugin]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                            left: 'prevYear,prev,next,nextYear today',
                            center: 'title',
                            right: 'dayGridMonth'
                        }}
                        events={challenge_locks.map(challenge_lock => {
                            if (color_index > COLORS.length - 1) {
                                color_index = 0;
                            }
                            color_index++;

                            return ({
                                id: challenge_lock.id,
                                title: challenge_lock.latest_challenge_version_name,
                                start: moment(challenge_lock.created_at * 1000).format(datesConstants.SYSTEM_DATE_TIME_FORMAT),
                                end: moment(challenge_lock.end_date * 1000).format(datesConstants.SYSTEM_DATE_TIME_FORMAT),
                                color: COLORS[color_index]
                            });
                        })}
                        eventClick={handleEventClick}
                        editable={true}
                    />
                </div>
            );
        }

        return null;
    }

    function renderAddChallengeLock() {
        return (
            <div className='add-challenge-lock'>
                <button className='btn btn-on-white no-wrap' onClick={event => {
                    event.preventDefault();
                    setShow(!show);
                }}>Add a new Challenge Lock
                </button>
                <div style={{display: (show ? 'initial' : 'none')}}>
                    <div className="section">
                        <div className="flex-row flex-box-0-10">
                            <div>
                                <div className="flex-row flex-box-0-10">
                                    <select
                                        value={challenge_code_id}
                                        onChange={event => setChallengeCodeId(event.target.value)}
                                        id="challenge_code_id"
                                        disabled={challenge_codes.length <= 0}
                                    >
                                        <option key='empty' value='' disabled={true}>Please select a Challenge</option>
                                        {challenge_codes.map(challenge_code => {
                                            return (
                                                <option
                                                    key={challenge_code.id}
                                                    value={challenge_code.id}
                                                >{challenge_code.latest_challenge_version_name}</option>
                                            )
                                        })}
                                    </select>
                                    <DatePicker
                                        onChange={end_date}
                                        date={setEndDate}
                                    />
                                </div>
                                <textarea
                                    className='locking-message'
                                    value={message}
                                    onChange={event => setMessage(event.target.value)}/>
                            </div>
                            <button
                                className='btn btn-on-white no-wrap'
                                onClick={event => {
                                    event.preventDefault();
                                    createChallengeLock();
                                }}
                                disabled={!(challenge_code_id && end_date)}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='challenge-locks'>
            <h3>Your District's Challenge Locks</h3>
            {renderAddChallengeLock()}
            {renderChallengeLocks()}
        </div>
    );
}
