import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from "react-redux";
import {toastActions} from "mindsets-js-sdk";


class ButtonCopyText extends Component {
    constructor(props) {
        super(props)
        this.state = {
            copied: false
        }
    }

    getButtonLabel() {
        return this.state.copied ? 'copied' : 'copy'
    }

    onClick() {
        return () => {
            this.props.whenClick()

            if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
                const textarea = document.createElement("textarea")
                textarea.textContent = this.props.text_to_copy
                textarea.style.position = "fixed"
                textarea.style.display = "hidden"
                document.body.appendChild(textarea)
                textarea.select()
                try {
                    const copied = document.execCommand("copy")
                    if (copied) {
                        this.setState({copied: !!copied})
                    } else {
                        throw "failed"
                    }
                    return copied
                } catch (ex) {
                    this.props.dispatch(toastActions.ErrorMessageAction("Copy to clipboard failed"))
                    return false
                } finally {
                    document.body.removeChild(textarea)
                }
            } else {
                this.props.dispatch(toastActions.ErrorMessageAction("Copy to clipboard failed"))
            }
        }
    }

    render() {
        return (
            <button className={this.props.button_class_name} onClick={this.onClick()}>
                <i className="far fa-copy"></i>
                <span>{this.getButtonLabel()}</span>
            </button>
        )
    }
}

ButtonCopyText.defaultProps = {
    button_class_name: 'btn btn-challenge',
    whenClick: () => {}
}

ButtonCopyText.propTypes = {
    text_to_copy: PropTypes.string.isRequired,
    button_class_name: PropTypes.string,
    whenClick: PropTypes.func,
    dispatch: PropTypes.func.isRequired
}

export default props => <ButtonCopyText {...props} dispatch={useDispatch()}/>;
